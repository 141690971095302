import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import GalleryList from "../gallery/GalleryList";
import TextDetail from "../gallery/TextDetail";

function Gallery(props){
    const {type ,group, init, userObj} = props;
    const history = useHistory();
    const cate = 'gallery'
    return (
        <>
        <Switch>
            <Route path="/event/gallery/" exact>
                <GalleryList cate={cate} type={type} group={group} init={init} userObj={userObj} />
            </Route>
            <Route path="/event/gallery/detail/:bbsId" exact>
                <TextDetail history={history} cate={cate} group={group} />
            </Route>
        </Switch>
        </>
        );

};

export default Gallery;
