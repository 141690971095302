import React, { useState, useEffect } from "react";
import {
    useLocation,
    useHistory,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import boardData from "../data/boardData.json";
import FooterBottomWhite from "../components/footer/FooterBottomWhite";
import { Layout, Row, Col, Menu, Dropdown } from "antd";
// import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';
import "../assets/styles/Board.css";
import BoardHeader from "../components/board/BoardHeader";
import BoardSidebar from "../components/board/BoardSidebar";
import BoardRouter from "./BoardRouter";

const { SubMenu } = Menu;
const { Header, Content, Sider, userObj } = Layout;

function BoardMain(props) {
    const { userObj, setUserObjFn, init } = props;
    let { groupValue, value } = useParams();
    // data에 있는 그룹 value
    const eachValue = { value };
    const groupMenu = { groupValue };
    const boardMenuData = boardData;
    const location = useLocation();
    const history = useHistory();

    // useEffect(()=>{
    //     refreshUser().then(r => console.log('token refreshed - Board Page'));
    // },[location.pathname])

    return (
        <div className="board-main-wrap">
            <Layout>
                <BoardHeader value={value} groupValue={groupValue} />
            </Layout>
            <Layout className="parent-wrap boardLayout">
                <Layout>
                    <BoardSidebar groupValue={groupValue} />
                    <Layout>
                        <Content
                            style={{ marginBottom: 50, paddingBottom: "50px" }}
                        >
                            <BoardRouter
                                location={location}
                                history={history}
                                groupValue={groupValue}
                                value={value}
                                userObj={userObj}
                                setUserObjFn={setUserObjFn}
                                init={init}
                            />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
            <FooterBottomWhite />
        </div>
    );
}

export default BoardMain;
