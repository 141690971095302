import React ,{ useState } from "react";
import "../assets/styles/static.css";
import { Link, Route, useHistory, Switch, useLocation } from "react-router-dom";
import Sbhistory from "../components/static-page/seongbuk/Sbhistory";
import OriginName from "../components/static-page/seongbuk/OriginName";
import Cultural from "../components/seongbuk/Cultural";
import Hfigure from "../components/seongbuk/Hfigure";

function SeongBuk(props){
  const { groupValue, value, init, userObj } = props;
  let history = useHistory();
  let location = useLocation();
return (
  <>
    <Switch>
      <Route
          name="sb-history"
          path="/seongbuk/sb-history">
        <Sbhistory
            location={location}
            value={value}
            />
      </Route>
      <Route
          name="named"
          path="/seongbuk/named">
        <OriginName
            location={location}
            value={value}
            />
      </Route>
      <Route
          name="cultural"
          path="/seongbuk/cultural">
        <Cultural
            location={location}
            value={value}
            init={init}
            userObj={userObj}
            />
      </Route>
      <Route
          name="hfigure"
          path="/seongbuk/hfigure">
        <Hfigure
            location={location}
            value={value}
            init={init}
            userObj={userObj}
            />
      </Route>
    </Switch>
</>
);

};

export default SeongBuk;
