import React from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { Image, Skeleton } from 'antd';
import { defaultApiAddress } from '../../services/api.helpers';

const BasicList = (props) => {
  const { data, pathName } = props;
  
  const cardItemRender = (data) => {
    if(Array.isArray(data)){
      return data.map((item, index) => {
        return (
          <div key={index}>
            <div className='GalleryPost'>
              <Link to ={`${pathName}/detail/${item.id}`}>
                <div className='boradImgBox'>
                  <Image src={defaultApiAddress+item.mediaUrl} 
                    alt={item.title} 
                    // placeholder={<Skeleton.Image className="skeletonImage" />}
                    preview={false}
                    />
                </div>
                <div className='boardTitleBox'>
                    <div className='GalleryPostTitle'>
                      {item.title}
                    </div>
                    <div className='GalleryPostSubtitle'>
                      {moment(item.createdAt).format('YYYY/MM/DD')}
                    </div>
                </div>
                </Link>
            </div>
          </div>
        )
      })
      }
  }

return (
  <div className='g-b-container'>
      { data !== null ? cardItemRender(data) : null }
  </div>
    );
  }
  
export default BasicList;