import React from "react";
import { Space, Divider } from "antd";
import { Link } from "react-router-dom";

function CategoryBtn(){
    return (
      <div className="categoryBtn">
            <Space size="large" wrap align="center" className="programIcon">
            <Link to="/program/program-all"
              className="icon-menu-box all" value="프로그램"
              >
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon00.png" alt="" />
                    </li>
                  <li>전체보기</li>
                </ul>
            </Link>
            <Divider type="vertical" style={{width:10, height:60}}/>
            <Link to="/program/list01"
              className="icon-menu-box" value="미술·서예">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon01.png" alt="" />
                    </li>
                  <li>미술·서예</li>
                </ul>
            </Link>
            <Link to="/program/list02"
              className="icon-menu-box" value="악기·음악">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon02.png" alt="" />
                    </li>
                  <li>악기·음악</li>
                </ul>
            </Link>
            <Link to="/program/list03"
              className="icon-menu-box" value="댄스·무용">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon03.png" alt="" />
                    </li>
                  <li>댄스·무용</li>
                </ul>
            </Link>
            <Link to="/program/list04"
             className="icon-menu-box" value="생활교양">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon04.png" alt="" />
                    </li>
                  <li>생활교양</li>
                </ul>
            </Link>
            <Link to="/program/list05"
             className="icon-menu-box" value="어린이">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon05.png" alt="" />
                    </li>
                  <li>어린이</li>
                </ul>
            </Link>
          </Space>
            <Space size="large" wrap align="baseline" className="color-tag-wrap">
                <div style={{ textAlign:"left" }}>
                    <span className="borderLine"></span>
                </div>
                    <div className="color-tag-div">
                        <p>
                            <span className="color-tag-info" style={{backgroundColor:"#2D9CDB"}}></span>
                            <span>신청가능</span>
                        </p>
                    </div>
                    <div className="color-tag-div">
                        <p>
                            <span className="color-tag-info" style={{backgroundColor:"#828282"}}></span>
                            <span>접수마감</span>
                        </p>
                    </div>
            </Space>
        </div>
    );
};

export default CategoryBtn;
