import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import BasicList from "../boardBasic/BasicList";
import AttachDetail from "../attach/AttachDetail";

function Notice(props){
    const { userObj, init } = props;
    const history = useHistory();
    const cate = 'notice';
    const type = 'attach';
    return (
        <>
        <Switch>
            <Route path="/commu/notice/" exact>
                <BasicList history={history} cate={cate} type={type} userObj={userObj} init={init} />
            </Route>
            <Route path="/commu/notice/detail/:bbsId" exact>
                <AttachDetail history={history} cate={cate}/>
            </Route>
        </Switch>
        </>
        );

};

export default Notice;
