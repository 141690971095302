import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation,useParams } from 'react-router-dom'
import {Space, Button, Typography, Row, Col, Modal, Form, Image, Skeleton } from 'antd';
import { ArrowLeftOutlined, RightCircleFilled } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import { defaultApiAddress } from '../../services/api.helpers';
import moment from 'moment';
import ApplyBtn from './ApplyBtn';
import axios from "axios";

const { Title, Text } = Typography;

export default function ProgramDetail(props) {
    const { userObj, setUserObjFn } = props;
    let userChkQuery = (userObj != null) ? (`?userId=${userObj.uid}`) : '';
    const history = useHistory();
    const location = useLocation();
    const [ loading, setLoading ] = useState(true);
    const [ detailData, setDetailData ] = useState(null);

    // date 포맷 state
    const [ duration, setDuration ] = useState(null);
    const { detailId } = useParams();
    const getDataAPI = () => {
            axios.get(`${defaultApiAddress}/courses/${detailId}${userChkQuery}`)
            .then(response => {
                setDetailData(response.data);
                console.log(response.data);
                numberWithComma((response?.data.payPrice != null) ? response.data.payPrice : response?.data.info?.programFee);
                 if(response.mediaUrl === null){
                     setLoading(true);
                 }else{
                     setLoading(false)
                 }
            })
        };

    const [ category, setCategory ] = useState(null);

    useEffect(() => {
        if(detailData?.category != null){
            switch(detailData?.category) {
                case 'LIST01': return setCategory('미술.서예'); break;
                case 'LIST02': return setCategory('악기·음악'); break;
                case 'LIST03': return setCategory('댄스·무용'); break;
                case 'LIST04': return setCategory('생활교양'); break;
                case 'LIST05': return setCategory('어린이'); break;
                case 'LIST06': return setCategory('야간'); break;
            }
        }
    },[detailData?.category]);


    const onHandleBack = () => {
        history.goBack();
    };

  useEffect(() => {
      if(detailData?.id == null){
          getDataAPI();
      }
  }, [location.pathname])

  useEffect(() => {
        if(detailData?.startDate === null){
      }else{
          // const coursePeriod = detailData.
        let day1 = moment(detailData?.startDate).format('YYYY-MM-DD');
        let day2 = moment(detailData?.endDate).format('YYYY-MM-DD');
        function durationWeeks(dayOne, dayTwo){
            return dayTwo.diff(dayOne, 'weeks', true);
        }

        let resultDuration = durationWeeks(moment(day1), moment(day2))
        let mathDuration = Math.round(resultDuration);
        setDuration(mathDuration);
    }
  }, [detailData?.startDate]);

  console.log(detailData?.category)


    const [fee, setFee] = useState(0);
    // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
  function numberWithComma(x){
      return setFee(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }

    return(
    <>
      <Row wrap align="middle" justify="start" style={{overflow:"hidden"}} className="programDetail">
        <Row className="detail-program-MainInfoWrap">
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={18}>
            </Col>
            <Col span={2} offset={0}>
            </Col>
        </Row>
        <Row className="programDetailInfoWrap">
            <Col className="info-first-col" span={24} sm={24} md={8}>
               <Space align="center" direction="vertical">
                   { loading === true ?
                        <Skeleton.Image style={{width:"315px", height: "315px" }} active /> :
                                <Image
                                    width="100%"
                                    src={defaultApiAddress+detailData?.mediaUrl}
                                    alt="thumbnail"
                                    preview={false}
                                />
                                    }
              </Space>
            </Col>
            <Col className="info-second-col" span={24} sm={24} md={16}>
                    <Row style={{height:"25%"}} align="top">
                        <Col span={24}>
                                <Title level={1}>{ detailData?.title?.length > 15 ? detailData?.title?.slice(0, 15)+'...' : detailData?.title }</Title>
                        </Col>
                        <Col span={24} style={{height:"30%"}}>
                                <span className="userLimitTag">강좌인원</span><span className="userLimitTxt">ㅤ신청 가능 인원 :ㅤ{detailData?.userLimitAll - ( detailData?.entryCountOff + detailData?.entryCountOn ) }명ㅤㅤ/ㅤㅤ모집 인원 :ㅤ{detailData?.userLimitAll}명</span>
                        </Col>
                    </Row>

                    <Row style={{height:"75%"}}>
                        <Col className="listCol" span={12}>
                            <ul>
                                <li><span className="listTitle">카테고리</span><span className="listData">{category}</span></li>
                                <li><span className="listTitle">강사명</span><span className="listData">{detailData?.instructor}</span></li>
                                <li><span className="listTitle">수강료</span><span className="listData">{fee}원</span></li>
                                <li><span className="listTitle">강의실</span><span className="listData">{detailData?.info?.lectureRoom}</span></li>
                            </ul>
                        </Col>
                        <Col className="listCol" span={12}>
                            <ul>
                                <li><span className="listTitle">시작일</span><span className="listData">{moment(detailData?.startDate).format('YYYY/MM/DD')}</span></li>
                                <li><span className="listTitle">수업일</span><span className="listData">{detailData?.info?.day.join(', ')}</span></li>
                                <li><span className="listTitle">교육시간</span><span className="listData">{detailData?.info?.startTime}~{detailData?.info?.endTime}</span></li>
                                <li><span className="listTitle">교육기간</span><span className="listData">{ duration !== null ? duration : <Skeleton.Avatar active size="small" /> }ㅤ주</span></li>
                            </ul>
                        </Col>
                    </Row>
            </Col>
        </Row>
        <div style={{width: '100%', maxWidth:1000, margin:"30px auto 0", textAlign:'left'}}>
            <RightCircleFilled style={{fontSize:14, color:"#FFD560", marginRight:10}} /><Text strong style={{fontSize:16}}>강좌소개</Text>
        </div>
        <Row className="qulii-body-row" align="middle" justify="center" style={{padding:"0 24px 24px"}} >
            {detailData ? (
                <>
                    <ReactQuill
                        value={detailData?.description}
                        readOnly={true}
                        theme={"bubble"}
                    />
                    <Col span={24} style={{margin:"25px 0"}}>
                        { detailData?.body }
                    </Col>
                </>
            ) : (
                <>
                        <div>로딩중...</div>
                </>
            )}
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
            <div className="bottomInquiry">
                <Title level={5} style={{margin:0, fontSize:16}}>찾으시는 내용이 없다면 1:1 문의를 이용하세요.</Title>
                <Text type="secondary" style={{fontSize:14}}>1:1 문의는 회원만 가능합니다.</Text>
                <Link to={{
                    pathname:"/commu/inquiry",
                    state: {
                            course: true,
                            title: detailData?.title,
                             type:'강좌문의,수강문의'}
                }}><Button>1:1문의 바로가기</Button></Link>
            </div>
            <Col span={24} style={{margin:"50px 0 25px"}}>
                {
                    detailData?.liveState === 'OPEN' || detailData?.state === 'OPEN'
                        ? <ApplyBtn userObj={userObj} setUserObjFn={setUserObjFn} detailData={detailData} history={history} />
                        : <Button className="btnApply" type="primary" disabled>강좌신청</Button>
                }

            </Col>
        </Row>
      </Row>
    </>
    );
}
