import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {BrowserRouter, Route, Switch, Link, useHistory} from "react-router-dom";
import "antd/dist/antd.css";
// import "./assets/styles/static.css";
import "./assets/styles/App.css";
import HeaderNaviMenu from "./components/navigation/HeaderNaviMenu";
import Main from "./routes/Main";
import NotFound from "./routes/NotFound";
import Board from "./routes/Board";
import MyPage from "./routes/MyPage";
import Auth from "./components/common/Auth";
import TermsOfUse from "./components/static-page/terms/TermsOfUse";
import PersonalTerm from "./components/static-page/terms/PersonalTerm";
import {authService, firebaseInstance} from "./middleware/fb";
import axios from "axios";
import {defaultApiAddress, defaultHeaders} from "./services/api.helpers";
import LoadingSpin from './components/common/LoadingSpin'

function App() {

    const history = useHistory();
    const [init, setInit] = useState(false);
    const setInitFn = function (e) {
        setInit(e);
    };
    const [userObj, setUserObj] = useState(null);
    const setUserObjFn = function (user) {
        setUserObj(user);
    };

    const logout = ()=>{
        setUserObj(null);
        setInit(false);
        authService.signOut();
    }

    const loginUser = async () => {
        authService.onAuthStateChanged((user) => {
            if (user) {
                setInit(true);
                let _uid=user.uid+'';
                let _photoURL=user.photoURL+'';
                let _providerByPhoto = (_photoURL.includes('facebook') ? 'facebook.com' : (_photoURL.includes('google') ? 'google.com' : null) );
                let _providerByUid = (_uid.includes('kakao') ? 'kakao' : (_uid.includes('naver') ? 'naver' : null) );

                // setUserObj({
                //     uid: user.uid,
                //     displayName: user.displayName,
                //     idToken: user._lat,
                //     email: user.email,
                //     photoURL: user.photoURL,
                //     updateProfile: (args) => user.updateProfile(args),
                //     userNameReal: '',
                //     phoneNumber: '',
                //     provider: _providerByPhoto || _providerByUid
                // });
                if (!user.displayName || !user.providerData.displayName) {     // user.displayName이 있다는것은 이미 로그인/가입 했다는거니 없을때 호출
                    const instance = axios.create({
                        baseURL: defaultApiAddress,
                        headers: defaultHeaders,
                        timeout: 10000,
                    });

                    const response = instance.get(
                        'users/' + user.uid + '?rckbp=true'
                    ).then(response => {
                        const _data = response.data;

                        if (_data) {
                            setUserObj({
                                uid: user.uid || _data.id,
                                displayName: _data.userName || user.displayName,
                                idToken: user._lat,
                                email: user.email || _data.email,
                                photoURL: user.photoURL || _data.photoUrl,
                                updateProfile: (args) => user.updateProfile(args),
                                userNameReal: _data.userNameReal || '',
                                phoneNumber: _data.phoneNumber || '',
                                provider: _data.provider
                            });
                        }
                    }).catch((error)=>{
                        if (error.response) {
                            console.log(error.response.data);
                        }
                    });
                }
            } else {
                setInit('notLogined');
                setUserObj(null);
            }
        });
    }

    useEffect(() => {
        // 잘못 구성된 네이버 서브도메인 관련 조치 사항
        // 서치어드바이져에서 사이트맵 다시제출 해보기도 수집재요청을 해도 수정이 안되서, 특정 도메인으로 들어올때 리다이렉트 조치 230515 by Sarc
        switch(window.location.pathname) {
            case '/05_lecture/01_nfo.php':
                window.location.href = '/program/course-guide';
                break;
            case '/01_art/05_contect.php':
                window.location.href = '/sbcc/map';
                break;
            case '/04_enjoy/01_not.php':
                window.location.href = '/event/e-culrture';
                break;
            case '/03_divide/01_bus.php':
                window.location.href = '/event/e-academic';
                break;
            case '/01_art/01_hello.php':
                window.location.href = '/sbcc/greetings';
                break;
            case '/02_chic/01_cul.php':
                window.location.href = '/seongbuk/sb-history';
                break;
        }

        loginUser().then(()=>{/*console.log('로그인 성공!')*/});
    }, []);

    const refreshToken = ()=>{   //일단 미사용(api.helpers 에 있는걸 우선사용 = props사용 최소화)
        return new Promise(async (resolve,reject) => {
            if (!!authService.currentUser) {
                authService.currentUser.getIdToken(true).then(function (idToken) {
                    // setToken(idToken);
                    // window.localStorage.setItem("accessToken", JSON.stringify(idToken));
                    // console.log('localStorage 저장(api helper) - ', window.localStorage.getItem("accessToken"));
                    resolve(idToken);
                }).catch(function (error) {
                    reject("만료됨");
                });
            }
        });
    };

    return (
        <BrowserRouter>
            <div className="App">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>성북문화원</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="성북동, 성북문화원, 성북문화원 홈페이지, 성북문화원 웹사이트, 성북동 문화강좌" />
                </Helmet>
                <div>
                    {init || userObj ? (
                        <HeaderNaviMenu isLoggedIn={Boolean(userObj)}
                                        userObj={userObj} setUserObjFn={setUserObjFn}
                        />
                    ) : (
                        <>
                            <HeaderNaviMenu isLoggedIn={Boolean(userObj)}
                                            userObj={null} setUserObjFn={setUserObjFn}
                            />
                            {/*<div>"로그인중..."</div>*/}
                        </>
                    )}
                </div>
                <Switch>
                    <Route path="/" component={Main} exact />
                    <Route path="/mypage" children={<MyPage userObj={userObj} setUserObjFn={setUserObjFn} />} />
                    <Route path="/termsofuse" exact render={() => <TermsOfUse />}/>
                    <Route path="/personalterm" exact render={() => <PersonalTerm />}/>
                    <Route path="/signin" exact render={() => <Auth userObj={userObj} setUserObj={setUserObj} setUserObjFn={setUserObjFn} setInitFn={setInitFn} />}/>
                    <Route path="/signup" exact render={() => <Auth userObj={userObj} setUserObj={setUserObj} setUserObjFn={setUserObjFn} setInitFn={setInitFn} />}/>
                    {init ? (
                        <>
                            <Route path="/:groupValue/:value" render={()=><Board userObj={userObj} setUserObjFn={setUserObjFn} init={init} />}/>
                            {/*<Route path="/:groupValue/:value" children={<Board userObj={userObj} setUserObjFn={setUserObjFn} init={init} />}/>*/}
                        </>
                    ) : (
                        <LoadingSpin />
                    )}
                    <Route path="*" component={NotFound}/>
                    {/*route를 통한 컴포넌트에는 아래의 방식으로 props를 전달해야함. render말고 component로 할경우 변경시마다 새로 component 재생성. render의 경우 렌더링만 다시 함.*/}
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default App;
