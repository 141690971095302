import React from 'react';

function TermsOfUse(){
    return(
        <>
            <div className="cd-faq">

                <h2 className="title">&#60; 성북문화원 홈페이지 &#62; 이용약관</h2>
                최종 수정일 : 2021년 3월 25일
                <br/>
                    <h1 className="utitle">제 1조 (목적)</h1>
                    <ul className="ulist">
                        <li>1. 본 약관은 성북문화원이 제공하는 &#60; 성북문화원 홈페이지 &#62; 서비스의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을
                            규정함을 목적으로 합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>2. &#60; 성북문화원 홈페이지 &#62; 서비스 이용 전에 이 약관을 주의 깊게 읽어 보시기 바라며, &#60; 성북문화원 홈페이지 &#62; 서비스를 이용하는
                            경우 이 약관에 동의한 것으로 간주됩니다. 이 약관은 성북문화원의 서비스인 &#60; 성북문화원 홈페이지 &#62; 최초
                            실행 시 고지되어 이용자로부터 동의를 받으며, 이용자는 이 약관을 &#60; 성북문화원 홈페이지 &#62; 서비스에서 다시 확인할 수 있습니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>3. 귀하가 본 약관을 위반하는 경우, 기관은 귀하에 대하여 계정 해지를 포함하되 이에 한정되지 아니하는 조치를 취할 수 있습니다. 귀하는 계정의 강제 중단 또는
                            해지로 인한 서비스 손실과 관련하여
                            기관이 귀하에게 상환 또는 환불을 제공할 의무가 없으며 그러하지 않을 것임을 인정합니다.
                        </li>
                    </ul>

                    <h1 className="utitle">제 2조 (용어의 정의)</h1>
                    <ul className="ulist">
                        <li>※ 이 약관에서 사용하는 용어의 의미는 다음과 같습니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li> 1. 서비스라 함은 이용자가 &#60; 성북문화원 홈페이지 &#62;의 자료열람 및 문화강좌 신청 및 조회 등과 같은 문화원에서 제공하는 부가적인 기능까지도 포함한
                            서비스를 말합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>2. 이용자라 함은 성북문화원 이용약관에 따라 기관 이용계약을 체결하고 기관이 제공하는 서비스를 이용하는 회원 중 본 약관에 동의하고 서비스를 이용하는 회원을
                            말합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>3. 이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 서비스 별 안내 및 관계법령에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</li>
                    </ul>

                    <h1 className="utitle">제 3조 (개인정보 보호)</h1>
                    <ul className="ulist">
                        <li>※ 본 서비스는 성북구 문화의 발전과 지역민의 문화생활 영위를 위해 개발되었으며, 문화강좌 신청, 문의하기 등의 서비스는 귀하에 대한 정보가 필요하며, 기관은 법적
                            근거가 있는 경우에 한하여 귀하의
                            정보를 사용합니다. 기관이 수집하는 정보, 정보의 사용처 및 기관 서비스를 사용할 때 귀하가 선택할 수 있는 사항에 관한 내용은 기관 개인정보취급방침을 참조해주시기
                            바랍니다.
                        </li>
                    </ul>

                    <h1 className="utitle">제 4조 (서비스의 이용)</h1>
                    <ul className="ulist">
                        <li>1. 이용자는 누구든지 무상으로 &#60; 성북문화원 홈페이지 &#62; 서비스를 이용할 수 있습니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li>2. &#60; 성북문화원 홈페이지 &#62; 서비스를 이용하고자 하는 이용자는 이 약관의 내용에 대하여 동의하고 서비스 시작 버튼을 누름으로써 &#60; 성북문화원
                            홈페이지 &#62; 서비스를 이용할 수 있습니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>3. 안전하고 적절한 이용: 기관 서비스를 이용하는 동안 주변에 유의하여 안전하게 플레이하여 주시기 바랍니다. 귀하는 귀하가 서비스 이용에 관한 전적인 책임을
                            부담하며, 서비스를 이용하여 본 약관에
                            요약된 관련 법령, 이벤트 정책 또는 지침을 위반하거나 다른 개인으로 하여금 그러하도록 장려하거나 이를 가능하게 하지 않을 것에 동의합니다. <br/></li>
                        <li>나아가, 귀하는 서비스 이용과 함께 불법 및 부적절하거나 상업적인 콘텐츠(아래 정의됨)를 제공하지 않을 것에 동의합니다. 귀하는 데이터 전송, 편집 또는 제거 요청을
                            포함하여 부정확하고 오해의 소지가
                            있거나 부적절한 콘텐츠를 전송하지 않을 것에 동의합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>4. 성북문화원은 부정행위를 금지하며, 부정행위 방지방안을 개선하기 위한 지속적인 조치를 취하고 있습니다. 부정행위는 서비스의 정상적인 행위 또는 규칙을 변경하거나
                            침해하려고 시도하거나 이를 실제로 행하는
                            행동을 포함합니다. 부정행위는 귀하가 자신을 위하여 또는 다른 이들을 대신하여 다음과 같은 행위를 하는 것을 포함하되 이에 한정되지 아니합니다.
                        </li>
                    </ul>
                    <ul className="ulist-circle">
                        <li>서비스에 무단 접근하는 행위(수정되거나 비공식적인 제3자 소프트웨어의 사용 포함)</li>
                        <li>복수의 계정으로 동일한 서비스를 이용하는 행위</li>
                        <li>계정을 공유하는 행위</li>
                        <li>각종 정보를 조작하는 기술</li>
                        <li>계정을 판매하거나 거래하는 행위</li>
                    </ul>
                    <ul className="ulist">
                        <li>※ 귀하는 기관이 부정행위, 사기 및 본
                            약관상 금지된 기타 행위를 감지하고 이에 대응하기 위하여 적법한 메커니즘을 도입할 수 있음에 동의합니다. 이는 이용여부 또는 해킹 및/또는 무단 소프트웨어의
                            존재여부를 확인하기 위하여 귀하의 기기를 검사하는 것을
                            포함합니다. 상세한 내용은 기관 개인정보취급방침을 참조해 주시기 바랍니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>5. 이용자는 &#60; 성북문화원 홈페이지 &#62; 서비스에서 본인의 활동내역만 조회할 수 있습니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li>6. 이용자가 제공한 활동내역은 기관이 제공하는 통계 및 엡 추천 서비스를 위한 데이터로 이용될 수 있습니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li>7. 이용자는 다른 법률에 특별한 규정이 없는 한 &#60; 성북문화원 홈페이지 &#62; 서비스 접속에 필요한 아이디를 및 비밀번호를 본인 이외에 제3자에게
                            누설해서는 안되며, 로그인 계정에 대한 관리책임은 이용자에게 있습니다.
                        </li>
                    </ul>

                    <h1 className="utitle">제 5조 (서비스 이용시간)</h1>
                    <ul className="ulist">
                        <li>1. 기관은 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 및 기관의 사정에 따라 &#60; 성북문화원
                            홈페이지 &#62; 서비스 제공을 일시적으로 중단할 수 있습니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>2. 제 1항의 경우 기관은 서비스 내 공지사항을 통해 이용자에게 이를 공지합니다. 다만, 기관이 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
                            있습니다.
                        </li>
                    </ul>

                    <h1 className="utitle">제 6조 (광고의 노출 등)</h1>
                    <ul className="ulist">
                        <li>1. 기관은 서비스 내에 기관 또는 제 3자의 상품, 용역 등에 대한 광고 메일 및 푸시 알람(모바일 PUSH 알림)(을)를 노출할 수 있으며, 이용자는 이에
                            동의합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>2. 기관은 서비스에 노출되는 제 3자의 상품, 용역 등에 대한 광고에 대하여는 어떠한 책임도 부담하지 않습니다.</li>
                    </ul>

                    <h1 className="utitle">제 7조 (서비스의 해지)</h1>
                    <ul className="ulist">
                        <li>1. &#60; 성북문화원 홈페이지 &#62; 서비스 해지는 &#60; 성북문화원 홈페이지 &#62; 회원 탈퇴 시, 기관의 안내에 따라 서비스 해지 종료 됩니다.
                            그 경우 이용자가 제공한 활동 내역은 등록된 게시물 등은 기관의 서비스 정책에 따라 처리됩니다.
                        </li>
                    </ul>

                    <h1 className="utitle">제 8조 (책임제한)</h1>
                    <ul className="ulist">
                        <li>1. 기관은 이용자의 입력 및 전송 정보를 기반으로 집계한 서비스 및 통계를 바탕으로 제공하는 추가 정보의 정확성에 대해서 보증하거나 책임지지 않습니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li>2. 이용자가 이 약관 및 관계법령을 위반한 경우, 기관은 이로 인하여 발생한 결과에 대해 책임을 지지 않습니다.</li>
                    </ul>

                    <h1 className="utitle">제 9조 (약관 외 준칙)</h1>
                    <ul className="ulist">
                        <li>1. 이 약관에 명시되지 않은 사항은 일반 관례에 따르며, 일반 관례와 이 약관의 내용이 상충되는 경우, 이 약관을 우선하여 적용합니다.</li>
                    </ul>

                    <h1 className="utitle">제 10조 (약관의 개정)</h1>
                    <ul className="ulist">
                        <li>1. 기관은 이 약관의 내용을 이용자가 쉽게 알 수 있도록 &#60; 성북문화원 홈페이지 &#62; 서비스 시작 화면에 게시합니다.</li>
                    </ul>
                    <ul className="ulist">
                        <li>2. 기관은 약관의 규제에 관련법률, 정보통신이용촉진 및 정보 보호 등에 관한 법률 (이하 정보통신망법) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
                            있습니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>3. 기관이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제 1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지
                            공지합니다. 다만, 이용자에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지, 로그인 시 동의 창 등의 전자적 수단을 통해 따로
                            명확히 통지하도록 합니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>4. 기관이 전항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는
                            통지하였음에도 이용자가 명시적으로 거부의사를 표명하지 아니한 경우 이용자가 개정약관에 동의한 것으로 봅니다.
                        </li>
                    </ul>
                    <ul className="ulist">
                        <li>5. 이용자가 개정약관의 내용에 동의하지 않는 경우 기관은 개정 약관의 내용을 적용할 수 없으며, 이 경우 이용자는 이용계약을 해지할 수 있습니다. 다만, 기존
                            약관을 적용할 수 없는 특별한 사정이 있는 경우에는 이용계약을 해지할 수 있습니다.
                        </li>
                    </ul>
                    <br/>
            </div>
        </>
    )
}

export default TermsOfUse;
