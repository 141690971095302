import React, {useState} from "react";
import KakaoLogin from "react-kakao-login";
import axios from "axios";
import {defaultApiAddress, keys} from "../../services/api.helpers";
import {authService} from "../../middleware/fb";
import {useHistory} from "react-router-dom";
import {Button, message, Modal, Result} from "antd";
import NaverLogin from "react-login-by-naver";
import {createBrowserHistory} from "history";

const client_token = keys.kakaoClientToken;

const KakaoSignin = (props) => {
    // isLogin 단순히 Auth에서 내려오는 props로 로그인인지 가입(signup)인지 분기해줌
    const { userObj, setUserObjFn, isLogin, validated, setInfoInputVisibleFn, setModeFn } = props;
    const [ errorModal, setErrorModal] = useState(false);
    const [ successModal, setSuccessModal] = useState(false);
    const [ sucMsg, setsucMsg ] = useState(null);
    const [ errMsg, setErrMsg ] = useState(null);
    const [ cbUrl, setCbUrl ] = useState('/');

    const history = useHistory();

    const handleSuccess = ()=>{

    }
    const handleFail = (url)=>{
        authService.signOut().then(r => {
            console.log('로그아웃됨.');
            history.push(url);
            createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
                forceRefresh: true
            });
        });
    }

    const onLogoutClick = () => {
        authService.signOut();
        // history.push("/");
    };

    async function requestToken (res) {
        const _isLogin = 'login';
        console.log('mode-',_isLogin);

        const accessToken = res.response.access_token;
        const refreshToken = res.response.refresh_token;
        const uid = 'kakao:' + res.profile.id;
        const displayName = res.profile.properties.nickname;
        const email = res.profile.kakao_account.email;
        const photoUrl = res.profile.kakao_account.profile.thumbnail_image_url || res.profile.properties.thumbnail_image;

        let value = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅
        }
        let value2 = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅,
        }
        setUserObjFn(() => {
            return {...value2};
        });


        let instance = axios.create({
            baseURL: defaultApiAddress,
            headers: {
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });
        const customTokenIssue = instance.post(
            'auth/',
            value
        ).then(async response => {
            let customToken = response.data.idToken;

            authService.signInWithCustomToken(customToken)
                .then(async (res) => {
                    instance = await axios.create({
                        baseURL: defaultApiAddress,
                        headers: {
                            "Authorization" : res.user._lat,
                            "Content-Type" : "application/json",
                            "Access-Control-Allow-Origin" : "*",
                        },
                        timeout: 5000,
                    });

                    const userExistChk = await instance.get(
                        'users/' + uid
                    ).then(async response => {  // 회원정보 획득 성공시 -> 로그인
                        if (_isLogin === 'signup') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                            // alert('이미 가입된 계정입니다! 로그인해 주세요.');
                            // message.error('이미 가입된 계정입니다! 로그인해 주세요.');
                            // 로그인시 오는 곳이므로, 이쪽으로 올 가능성 낮음
                            // await setErrorModal(true);
                            // await setCbUrl('/signin');
                            // await setErrMsg('이미 가입된 계정입니다! 로그인해 주세요.');
                            // onLogoutClick();
                        } else if (_isLogin === 'login') {
                            console.log(res);
                            if (!res.data) {
                                await setCbUrl('/signin');
                                await setErrMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                await setErrorModal(true);
                            }
                            await instance.put(
                                'users/' + res.user.uid,
                                {id: res.user.uid, userName: displayName, provider:'kakao'}
                            ).then(response => {
                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    setUserObjFn(() => {
                                        let _userObj = {...userObj};
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                        _userObj.provider = response.data.provider;
                                        _userObj.uid = response.data.id;
                                        _userObj.email = response.data.email;
                                        _userObj.displayName = response.data.userName;
                                        _userObj.photoURL = response.data.photoUrl;
                                        _userObj.idToken = res.user._lat;
                                        return _userObj;
                                    });
                                }
                                setModeFn('done');
                            }).catch(async (error)=>{
                                if (error.response) {
                                    console.log(error.response.data);
                                    if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                        // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                        // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                        await setCbUrl('/signin');
                                        await setErrMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        await setErrorModal(true);
                                    }
                                }
                            });
                        }
                    }).catch((error) => {
                        if (_isLogin === 'login') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                            // alert('가입이력이 없습니다.');
                            // message.error('가입이력이 없습니다.');
                            // setErrorModal(true);
                            // setCbUrl('/signup');
                            // setErrMsg('가입이력이 없습니다.');
                            setModeFn('not-registered');
                            onLogoutClick();
                            setUserObjFn(null);
                            // onLogoutClick();
                            // return false;
                        } else if (_isLogin === 'signup') {
                            setInfoInputVisibleFn(true);
                            console.log(res.user);
                            instance.put(
                                'users/' + res.user.uid,
                                {id: res.user.uid, userName: displayName, provider:'kakao'}
                            ).then(response => {
                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    setUserObjFn(() => {
                                        let _userObj = {...userObj};
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                        _userObj.provider = response.data.provider;
                                        _userObj.uid = response.data.id;
                                        _userObj.email = response.data.email;
                                        _userObj.displayName = response.data.userName;
                                        _userObj.photoURL = response.data.photoUrl;
                                        _userObj.idToken = res.user._lat;
                                        return _userObj;
                                    });
                                }
                            }).catch(async (error)=>{
                                if (error.response) {
                                    console.log(error.response.data);
                                    if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                        // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                        // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                        await setCbUrl('/signin');
                                        await setErrMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        await setErrorModal(true);
                                    }
                                }
                            });
                        }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                    }
                });
        })
    }

    async function requestTokenSignup (res) {
        const _isLogin = 'signup';
        console.log('mode-',_isLogin);

        const accessToken = res.response.access_token;
        const refreshToken = res.response.refresh_token;
        const uid = 'kakao:' + res.profile.id;
        const displayName = res.profile.properties.nickname;
        const email = res.profile.kakao_account.email;
        const photoUrl = res.profile.kakao_account.profile.thumbnail_image_url || res.profile.properties.thumbnail_image;

        let value = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅,
        }
        let value2 = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'kakao', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅,
        }
        setUserObjFn(() => {
            return {...value2};
        });

        let instance = axios.create({
            baseURL: defaultApiAddress,
            headers: {
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });
        const customTokenIssue = instance.post(
            'auth/',
            value
        ).then(async response => {
            let customToken = response.data.idToken;

            authService.signInWithCustomToken(customToken)
                .then(async (res) => {
                    instance = await axios.create({
                        baseURL: defaultApiAddress,
                        headers: {
                            "Authorization" : res.user._lat,
                            "Content-Type" : "application/json",
                            "Access-Control-Allow-Origin" : "*",
                        },
                        timeout: 5000,
                    });

                    const userExistChk = await instance.get(
                        'users/' + uid
                    ).then(async response => {  // 회원정보 획득 성공시 -> 로그인
                        if (_isLogin === 'signup') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                            // alert('이미 가입된 계정입니다! 로그인해 주세요.');
                            // message.error('이미 가입된 계정입니다! 로그인해 주세요.');

                            // await setCbUrl('/signin');
                            // await setErrMsg('이미 가입된 계정입니다! 로그인해 주세요.');
                            // await setErrorModal(true);
                            setModeFn('already-registered');
                            onLogoutClick();
                            setUserObjFn(null);
                        } else if (_isLogin === 'login') {
                            console.log(res);
                            await instance.put(
                                'users/' + res.user.uid,
                                {id: res.user.uid, userName: displayName, provider:'kakao'}
                            ).then(response => {
                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    setUserObjFn(() => {
                                        let _userObj = {...userObj};
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                        _userObj.provider = response.data.provider;
                                        _userObj.uid = response.data.id;
                                        _userObj.email = response.data.email;
                                        _userObj.displayName = response.data.userName;
                                        _userObj.photoURL = response.data.photoUrl;
                                        _userObj.idToken = res.user._lat;
                                        return _userObj;
                                    });
                                }
                            }).catch(async (error)=>{
                                if (error.response) {
                                    console.log(error.response.data);
                                    if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                        // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                        // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                        await setCbUrl('/signin');
                                        await setErrMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        await setErrorModal(true);
                                    }
                                }
                            });
                        }
                    }).catch(async (error) => {

                        if (_isLogin === 'login') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                            // alert('가입이력이 없습니다.');
                            // message.error('가입이력이 없습니다.');
                            // 회원가입시 터지는 곳이므로, 보통 여기로 올 가능성은 없음
                            // await setCbUrl('/signup');
                            // await setErrMsg('가입이력이 없습니다.');
                            // await setErrorModal(true);
                        } else if (_isLogin === 'signup') {
                            setInfoInputVisibleFn(true);
                            console.log(res.user);
                            await instance.put(
                                'users/' + res.user.uid,
                                {id: res.user.uid, userName: displayName, provider:"kakao"}
                            ).then(response => {
                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    setUserObjFn(() => {
                                        let _userObj = {...userObj};
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                        _userObj.provider = response.data.provider;
                                        _userObj.uid = response.data.id;
                                        _userObj.email = response.data.email;
                                        _userObj.displayName = response.data.userName;
                                        _userObj.photoURL = response.data.photoUrl;
                                        _userObj.idToken = res.user._lat;
                                        return _userObj;
                                    });
                                }
                            }).catch(async (error)=>{
                                if (error.response) {
                                    console.log(error.response.data);
                                    if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                        // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                        // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                        await setErrorModal(true);
                                        await setCbUrl('/signin');
                                        await setErrMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                    }
                                }
                            });
                        }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                    }
                });
        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data);
            }
        });
    }

    return(
        <>
            {
                (isLogin === 'login')
                ? <KakaoLogin
                        token={client_token}
                        onSuccess={requestToken}
                        onFail={console.error}
                        onLogout={console.info}
                        render={({ onClick }) => {
                            return (
                                <button
                                    className='before kakaoBtn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClick();
                                    }}
                                >카카오 간편 로그인
                                </button>
                            );
                        }}
                    />
                : <KakaoLogin
                        token={client_token}
                        onSuccess={requestTokenSignup}
                        onFail={console.error}
                        onLogout={console.info}
                        render={({ onClick }) => {
                            return (
                                <button
                                    className='before kakaoBtn'
                                    onClick={(e) => {
                                        if (window.location.pathname.includes('signup') && !validated) {
                                            // alert('회원가입시 이용약관 및 개인정보처리방침 동의가 필요합니다.');
                                            // message.error('회원가입시 이용약관 및 개인정보처리방침 동의가 필요합니다.');
                                            return false;
                                        }
                                        e.preventDefault();
                                        onClick();
                                    }}
                                >카카오 간편 회원가입
                                </button>
                            );
                        }}
                    />
            }
            <Modal
                visible={errorModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="error"
                    title="회원가입에 실패하였습니다."
                    subTitle={errMsg}
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={(e)=>{handleFail(cbUrl)}}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
            <Modal
                visible={successModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="success"
                    title="알림"
                    subTitle={sucMsg}
                    extra={[
                        <Button className="btnApply" type="primary" key="console" onClick={handleSuccess}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
        </>
    );
};

export default KakaoSignin
