import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Input, Modal, Result} from 'antd';
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import moment from "moment";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {authService} from "../../middleware/fb";

const MyInfo = (props) => {
    const { userObj, setUserObjFn, token } = props;
    const location = useLocation();
    // let { info } = useParams();
    let { page } = useParams();
    const [ data, setData ] = useState(null);
    const [ provider, setProvider ] = useState(null);
    const [ signUpDate, setSignUpDate ] = useState('2021/00/00');
    const [applyform] = Form.useForm();
    const [ successModal, setSuccessModal] = useState(false);
    const [ errorModal, setErrorModal] = useState(false);
    const history = useHistory();
    const [showMessageErr, setShowMessageErr] = useState(false);
    const [messageErr, setMessageErr] = useState(null);
    const [localToken, setLocalToken] = useState(null);

    const onHandleBack = () => {
        history.push(`/mypage`);
    };

    const errorAlert = ()=>{
        setTimeout(()=>{
            setShowMessageErr(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="오류"
                description={messageErr}
                type="error"
                showIcon
                closable={true}
            />
        )
    }

    const handleCancel = () => {
        setErrorModal(false);
        authService.signOut();
        history.push('/signin');
    }
    const handleSuccess = () => {
        setSuccessModal(false);
        refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
    }
    const userInfoUpdate = async function(data) {
        await axios.put(`${defaultApiAddress}/users/${userObj.uid}`, data, {headers: {
                "Authorization" : localToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(function (response){
                setSuccessModal(true);
            })
            .catch(function (error) {
                setErrorModal(true);
                console.log(error)
                if(error.response){
                    let err_msg = error.response.data;
                    // setErrorMsg(err_msg.message);
                    // errorResult();
                    setMessageErr(err_msg.message);
                    setShowMessageErr(true);
                }
            });
    }

    const updateRevert = ()=>{
        history.push('/mypage/');
    }
    const updateFailed = (errorInfo) => {
        console.log(errorInfo);
    }

    const getDataAPI = async(_idToken) => {
        // setSuccessModal(false);
        setLocalToken(_idToken);    // put처럼 함수에 포함하기 어려운 경우
        if (userObj != null) {
            await axios.get(`${defaultApiAddress}/users/${userObj.uid}`, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(async response => {
                    await setData({userNameReal: response.data.userNameReal, phoneNumber: response.data.phoneNumber});
                    await setSignUpDate(moment(response.data.createdAt).format('YYYY년 MM월 DD일'));
                    let _numberCheck = response.data.phoneNumber;

                    if (!!response.data.phoneNumber) {
                        _numberCheck = _numberCheck.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
                    }

                    await applyform.setFieldsValue({
                        "userNameReal": response.data.userNameReal,
                        "phoneNumber": _numberCheck
                    });

                    if(userObj.provider != null || userObj.provider !== ''){
                        let _uid = userObj.uid+'';  //undefined되도 그냥 스트링으로 바꿈
                        switch(userObj.provider) {
                            case 'google.com' : setProvider('구글');break;
                            case 'facebook.com' : setProvider('페북');break;
                            case 'kakao' : setProvider('카카오');break;
                            case 'naver' : setProvider('네이버');break;
                            // case 'custom' : setProvider(userObj ? (_uid.includes('kakao') ? '카카오' : '네이버') : '');break;
                            default: setProvider('알수없음');break;
                        }
                    }
                }).catch(function (error) {
                    setErrorModal(true);
                    if(error.response){
                        let err_msg = error.response.data;
                        // setErrorMsg(err_msg.message);
                        // errorResult();
                        setMessageErr(err_msg.message);
                        setShowMessageErr(true);
                    }
                });
        }
    };

    useEffect(() => {
        if (userObj != null) {
            refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
        }
        return () => {};
    }, [location.pathname]);

    return(
        <>
            <div className='mycontainer myinfo'>
                <Button onClick={onHandleBack} type="text" className="historyBtn" ghost>
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
                <h2>기본 정보 수정</h2>
                <Form className='mypageBox'
                      form={applyform}
                      layout="vertical"
                      onFinish={userInfoUpdate}
                      onFinishFailed={updateFailed}>
                    <table>
                        <colgroup>
                            <col width='180px'/>
                            <col width='70%'/>
                        </colgroup>
                        <thead>
                        </thead>
                        <tbody>
                        <tr>
                            <th>
                                <div>E-mail</div>
                            </th>
                            <td><span>{userObj ? userObj.email : ''}</span></td>
                        </tr>
                        <tr>
                            <th>
                                <div>이름</div>
                            </th>
                            <td className='changeinput'>
                                <Form.Item
                                    // label="이름"
                                    name="userNameReal"
                                    rules={[{ required: true, message: '이름을 입력하지 않았습니다.'}]}
                                >
                                    <Input />
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div>연락처</div>
                            </th>
                            <td className='changeinput'>
                                <Form.Item
                                    // label="이름"
                                    name="phoneNumber"
                                    rules={[{ required: true, message: '전화번호를 입력하지 않았습니다.'}]}
                                >
                                    <Input />
                                </Form.Item>
                                <sub>예) 010-0000-0000</sub>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div>계정 연동 정보</div>
                            </th>
                            <td><span>{provider}</span></td>
                        </tr>
                        <tr>
                            <th>
                                <div>가입일</div>
                            </th>
                            <td>{signUpDate}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='ButtonWrapper'>
                        <Button className="btnL" onClick={(e)=>{e.preventDefault();updateRevert();}}>
                            취소
                        </Button>
                        <Button type="primary" htmlType="submit" className="btnL" key="submit" >
                            수정
                        </Button>
                    </div>
                </Form>
            </div>
            <Modal
                visible={successModal}
                className="applyModal"
                style={{top:'25vh'}}
                width="100%"
                footer={null}>
                <Result
                    status="success"
                    title="수정되었습니다."
                    // subTitle="로그인 후 다시 시도해 주세요."
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={handleSuccess}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
            <Modal
                visible={errorModal}
                className="applyModal"
                style={{top:'25vh'}}
                width="100%"
                footer={null}>
                <Result
                    status="error"
                    // status="warning"
                    title="로그인 오류!"
                    subTitle="로그인 후 다시 시도해 주세요."
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={handleCancel}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
            {showMessageErr ? errorAlert() : null}
        </>

    )
}

export default MyInfo;
