import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import axios from "axios";
import { Tabs, Radio, Card, Row, Col, Tag, Divider, Skeleton  } from "antd";
import {defaultApiAddress} from "../../services/api.helpers";
import { ArrowRightOutlined , PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

function TabView() {

  const [ data, setData ] = useState(null);
  const [ loading, setLoading] = useState(true);
  const history = useHistory();
  let tabView;

  const getBoardAPI = () => {
    axios.get(`${defaultApiAddress}/boards?type=attach&cate=notice&rpp=3&page=0`)
    .then(res => {
      console.log(res?.data);
      setData(res?.data?.results);
      console.log(res?.data?.results);
      setLoading(false);
    })
    .catch(err => console.log(err))
    };

  useEffect(() => {
    if(data == null){
      getBoardAPI();
      console.log(data);
    }
  }, [])

const [ values, setValues ] = useState(null);

  const onTabClick = (value) => {
    console.log(value);
    setValues(value);
  }

  console.log(values);

  useEffect(() => {
    if(values === '1'){
      matchBoardAPI('notice', 'attach');
    }else if(values === '2'){
      matchBoardAPI('news', 'attach');
    }else if( values === '3'){
      matchBoardAPI('public', 'attach');
    }
  },[values])


  const matchBoardAPI = (cate, type) => {
      console.log(cate)
    if(cate !== null){
       axios.get(`${defaultApiAddress}/boards?type=${type}&cate=${cate}&rpp=3&page=0`)
        .then(res => {
          console.log(res?.data);
          setData(res?.data?.results);
          console.log(res?.data?.results);
        })
        .catch(err => console.log(err))
          setData({});
        }
    };

    if (!!data) {
      tabView = <div className="card-container">
        <Tabs defaultActiveKey="1" type="card" onTabClick={onTabClick}>
          <TabPane tab="공지사항" key="1">
            <div className="site-card-wrapper">
                <Link to="/commu/notice" className="board-list-more">공지사항 바로가기 <ArrowRightOutlined style={{fontSize:'0.75rem', margin:'-1px 2.5px 1px' }} /></Link>
              <Row gutter={16}>
                <Col span={24} md={24} lg={8} >
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[0] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#2F80ED">공지사항</Tag>}
                      </li>
                      <li className="card-title">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[0]?.id}`}>{data[0]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[0]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <span>{moment(data[0]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={24} lg={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[1] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#2F80ED">공지사항</Tag>}
                      </li>
                      <li className="card-title">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[1]?.id}`}>{data[1]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[1]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[1]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={24} lg={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[2] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#2F80ED">공지사항</Tag>}
                      </li>
                      <li className="card-title">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[2]?.id}`}>{data[2]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/notice/detail/${data[2]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[2]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="보도자료" key="2">
            <div className="site-card-wrapper">
              <Link to="/commu/news" className="board-list-more">보도자료 바로가기 <ArrowRightOutlined style={{fontSize:'0.75rem', margin:'-1px 2.5px 1px' }} /></Link>
              <Row gutter={16}>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[0] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#27AE60">보도자료</Tag>}
                      </li>
                      <li className="card-title">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[0]?.id}`}>{data[0]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[0]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[0]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[1] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#27AE60">보도자료</Tag>}
                      </li>
                      <li className="card-title">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[1]?.id}`}>{data[1]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[1]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[1]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[2] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#27AE60">보도자료</Tag>}
                      </li>
                      <li className="card-title">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[2]?.id}`}>{data[2]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/news/detail/${data[2]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[2]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="정보공개" key="3">
            <div className="site-card-wrapper">
            <Link to="/commu/public" className="board-list-more">정보공개 바로가기 <ArrowRightOutlined style={{fontSize:'0.75rem', margin:'-1px 2.5px 1px' }} /></Link>
              <Row gutter={16}>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[0] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#BB6BD9">정보공개</Tag>}
                      </li>
                      <li className="card-title">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[0]?.id}`}>{data[0]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[0]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[0] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[0]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[1] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#BB6BD9">정보공개</Tag>}
                      </li>
                      <li className="card-title">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[1]?.id}`}>{data[1]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[1]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[1] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[1]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col span={24} md={8}>
                  <Card bordered={false}>
                    <ul className="card-wrap">
                      <li className="card-tag">
                        { data[2] == null
                            ?  <Tag color="#ffffff" style={{visibility:'hidden'}}></Tag>
                            : <Tag color="#BB6BD9">정보공개</Tag>}
                      </li>
                      <li className="card-title">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'100px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[2]?.id}`}>{data[2]?.title}</Link>
                        }
                      </li>
                      <li className="small-text">
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'20px'}}  />
                            :
                            <Link to={`/commu/public/detail/${data[2]?.id}`}>더보기 +</Link>
                        }
                        <Divider style={{margin: '2px 0', borderColor:'#DEDEDE'}} />
                        { data[2] == null ?  <Skeleton.Input size="small" style={{width:'50px'}}  />
                            :
                            <span>{moment(data[2]?.createdAt).format('YYYY/MM/DD')}</span>
                        }
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>

        </Tabs>
      </div>
    } else {
      tabView = <></>;  // 불러오는중
    }

    return (
        <>
          {tabView}
        </>
    );
}

export default TabView;
