import React ,{ useState, useEffect }from "react";
import { useLocation } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import titleName from "../../data/boardTitle.json";

const { Header } = Layout;


const BoardHeader = (props) => {
    const { value, groupValue } = props;
    const titledata = titleName;
    const curvalue = { value };
    const location = useLocation();
    const [ boardTitle, setBoardTitle ] = useState(null);


    useEffect(() =>{
        if(value === "detail"){
            // const parentvalue = location.state.value;
            // const curTitle = titledata.filter(item => item.value === parentvalue);
            return setBoardTitle("프로그램 상세보기");
        }else{
            const curTitle = titledata.filter(item => item.value === curvalue.value);
            return setBoardTitle(curTitle[0].title);
        }
    },[location.pathname]);

    return (
        <Header style={{marginBottom:50}}>
            <Row className="bw-titleWrap" justify="center" align="middle">
                <Col span={24}>
                   <span className="bw-title">{ boardTitle ? boardTitle : null }</span>
                </Col>
            </Row>
        </Header>
    )
}

export default BoardHeader;