import React from 'react';
import { Link, Route, Router, Switch, useRouteMatch, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import { MenuOutlined, CopyOutlined } from "@ant-design/icons";
import { Drawer, Button, Tabs } from "antd";
const { TabPane } = Tabs;


function Sitemap() {
    return(
        <div className='Sitemap'>
                    <div className='nav-wrapper'>
            <ul>
                <li>
                    <div>문화원소개</div>
                    <ul className="subListUl-m">
                        <Link  to="/sbcc/greetings" >
                        <li>인사말<CopyOutlined /></li>
                        </Link>
                        <Link  to="/sbcc/history">
                        <li>연혁<CopyOutlined /></li>
                        </Link>
                        <Link  to="/sbcc/organization-c">
                        <li>조직도<CopyOutlined /></li>
                        </Link>
                        <Link  to="/sbcc/business">
                        <li>주요사업<CopyOutlined /></li>
                        </Link>
                        <Link  to="/sbcc/executives">
                        <li>임원현황<CopyOutlined /></li>
                        </Link>
                        <Link  to="/sbcc/map">
                        <li>오시는길<CopyOutlined /></li>
                        </Link>
                    </ul>
                </li>
                <li><div>문화강좌</div>
                    <ul className="subListUl-m">
                        <Link  to="/program/course-guide">
                        <li>수강안내<CopyOutlined /></li>
                        </Link>
                        <Link  to="/program/program-all">
                        <li>프로그램<CopyOutlined /></li>
                        </Link>
                        <ul>
                        <Link  to="/program/list01">
                            <li>미술·서예</li>
                        </Link>
                        <Link  to="/program/list02">
                            <li>악기·음악</li>
                        </Link>
                        <Link  to="/program/list03">
                            <li>댄스·무용</li>
                        </Link>
                        <Link  to="/program/list04">
                            <li>생활교양</li>
                        </Link>
                        <Link  to="/program/list05">
                            <li>어린이</li>
                        </Link>
                        </ul>
                    </ul>
                </li>
              <li><div>행사안내</div>
              <ul className="subListUl-m">
                <Link  to="/event/e-culrture">
                  <li>문화행사<CopyOutlined /></li>
                </Link>
                <Link  to="/event/e-academic">
                  <li>학술행사<CopyOutlined /></li>
                </Link>
                <Link  to="/event/gallery">
                  <li>갤러리<CopyOutlined /></li>
                </Link>
              </ul>
              </li>
              <li><div>성북학</div>
                <ul className="subListUl-m">
                    <Link  to="/seongbuk/sb-history">
                    <li>역사<CopyOutlined /></li>
                    </Link>
                    <Link  to="/seongbuk/named">
                    <li>동명유래<CopyOutlined /></li>
                    </Link>
                    <Link  to="/seongbuk/cultural">
                    <li>문화유산<CopyOutlined /></li>
                    </Link>
                    <Link  to="/seongbuk/hfigure">
                    <li>인물<CopyOutlined /></li>
                    </Link>
                </ul>
              </li>
              <li><div>커뮤니티</div>
              <ul className="subListUl-m">
                    <Link  to="/commu/notice">
                    <li>공지사항<CopyOutlined /></li>
                    </Link>
                    <Link  to="/commu/inquiry">
                    <li>문의 및 민원<CopyOutlined /></li>
                    </Link>
                    <Link  to="/commu/family-sites">
                    <li>관련사이트<CopyOutlined /></li>
                    </Link>
                    <Link  to="/commu/news">
                    <li>보도자료<CopyOutlined /></li>
                    </Link>
                    <Link  to="/commu/reference">
                    <li>자료실<CopyOutlined /></li>
                    </Link>
                    <Link  to="/commu/public">
                    <li>정보공개<CopyOutlined /></li>
                    </Link>
                </ul>
              </li>
            </ul>
        </div>
        </div>
    )
}

export default Sitemap;
