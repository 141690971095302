import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import {Space, Button, Typography, Row, Col, Image, Divider, List, Spin } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { defaultApiAddress } from '../../services/api.helpers';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import axios from "axios";
import moment from 'moment';
// import TextSkeletone from './TextSkeletone';
import '../../assets/styles/galleryboard.css';

const { Title, Text } = Typography;

const TextDetail = (props) => {
	const { cate, group } = props;
    const { bbsId } = useParams();
    const history = useHistory();
    const [ detailData, setDetailData ] = useState(null)
    const [ loading, setLoading ] = useState(true);

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

    useEffect(() => {
        let ignore = false;
        async function fetchDetailData() {
            setDetailData(null);
            const result = await axios.get(`${defaultApiAddress}/boards/${bbsId}?cate=${cate}`);
               if(!ignore) {
                 setDetailData(result.data);
                 console.log(result.data);
                 setLoading(false);
                }
          }
          fetchDetailData();
        return () => { ignore = true }

      }, [history.location]);


    const onHandleBack = () => {
        history.push(`/${group}/${cate}/`);
    };

    const curpath = `/${group}/${cate}/detail/`;



// nearRow 는 [0]이 이전게시물, [1]이 다음 게시물.
const prevFunction = () => {
    if(detailData?.nearRow[0] == null){
        return setprevLink({
            link:null,
            title:'이전 게시물이 존재하지 않습니다.'
        })
    }else{
        return setprevLink({
            link:curpath + detailData?.nearRow[0].id,
            title:detailData?.nearRow[0].title
        })
    }
}

const nextFunction = () => {
        if(detailData?.nearRow[1] == null){
           setnextLink({
               link:null,
               title:'다음 게시물이 존재하지 않습니다.'
           })
        }else{
        setnextLink({
            link:curpath + detailData?.nearRow[1].id,
            title:detailData?.nearRow[1].title
        })
       }
    return () => {};
}


useEffect(() => {
    if(detailData !== null){
        nextFunction();
        prevFunction();
    }else return () => {};
},[detailData?.nearRow]);

    return(
		<>
			{ loading ? (
                <>
                <Spin />
                    {/* <TextSkeletone /> */}
                </>
             ) : (

        <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
            <Row className="detailMainInfoWrap">
                <Col span={2}>
                    <Button onClick={onHandleBack} type="text">
                        <ArrowLeftOutlined
                            style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                        />
                        <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                    </Button>
                </Col>
                <Col span={24} style={{margin:"50px 0 0px", padding:"0 20px"}}>
                    <Title className="titleText">
                        { detailData?.title?.slice(0, 100) }
                    </Title>
                    <Text type="secondary" className="secondaryText">
                        <Space>
                        성북문화원
                        {moment(detailData?.createdAt).format('YYYY/MM/DD')}
                        </Space>
                    </Text>
                    <Divider className="titleDivider" />
                </Col>
            </Row>
            {/* <Row style={{padding:"0 0 24px"}}>
                <Col className="listCol" span={6}>
                    <Title level={5}>분류1</Title><span>{detailData.depth1}</span>
                </Col>
                <Col className="listCol" span={6}>
                    <Title level={5}>분류2</Title><span>{detailData.depth2}</span>
                </Col>
            </Row> */}
{/*            210701 성북문화원측 요청으로 인해 열화되 보이는 썸네일 이미지는 내부에선 안보이도록 처리 Sarc */}
{/*            <Row align="middle" className="gallerytext-imgWrap" justify="center" style={{padding:"0 24px 24px", minHeight:"500px"}} >
                <Image
                    width="100%"
                    src={defaultApiAddress+detailData?.mediaUrl}
                    alt="image"
                    preview={false}
                    // placeholder={true}
                />
            </Row>*/}
            <Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >
                        <ReactQuill
                            value={detailData?.description}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Row>
                        <Row align="middle" justify="center" className="detailFooterWrap">
                            <Divider className="titleDivider" />
                            <Col span={24} style={{margin:"0 0 25px"}}>
                                <List
                                    className="prevnextList"
                                    itemLayout="horizontal"
                                >
                                    <List.Item
                                    actions={[<a key="list-loadmore-edit" href={nextLink?.link}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href={nextLink?.link}>{nextLink?.title}</a>]}
                                    />
                                    <List.Item
                                    actions={[<a key="list-loadmore-edit" href={prevLink?.link}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href={prevLink?.link}>{prevLink?.title}</a>]}
                                    />
                                </List>
                            </Col>
                        </Row>
                    </Row>
              ) }
			</>
    )
}

export default TextDetail;
