import React, {useEffect, useState} from "react";
import moment from "moment";
import { Col, Card, Skeleton, Image } from "antd";
import { Link } from "react-router-dom";
import { defaultApiAddress } from '../../services/api.helpers';

const CardItem = (props) => {
  const { res, index, value } = props;

  useEffect(() => {
    numberWithComma((res?.payPrice != null) ? res.payPrice : res?.info?.programFee);
  }, [res])

  const applyClose = () => {
        return (
          <div className="card-stste-tag" style={{backgroundColor:"#828282"}}>
            <span className="card-tag-text">접수마감</span>
          </div>
        )
      };

      const applyOpen = () => {
        return (
          <div className="card-stste-tag" style={{backgroundColor:"#2D9CDB"}}>
            <span className="card-tag-text">신청가능</span>
        </div>
        )
    };

  const [fee, setFee] = useState(0);
  // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
  function numberWithComma(x){
    return setFee(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
}

  return (
        <>
          <div key={index} value={res.key} id="cardBox">
          <Col span={6} xs={24} sm={12} lg={6} xl={6} key={index} >
            { res.liveState === 'OPEN' || res.state === 'OPEN' ? applyOpen() : applyClose() }
              <Link to={{
                pathname:`/program/detail/${res.id}`,
                state: {
                  title:`${res.title}`,
                  value: `${value}`,
                  id: `${res.id}`
                }
              }}
              >
              <Card
                  key={res.index}
                  className="program-list-card"
                  cover={
                          <Image
                              placeholder={<Skeleton.Image style={{ marginTop:"-20px",width:"255px", height:"235px" }} />}
                              className="card-thumbnail-img"
                              alt="example"
                              src={defaultApiAddress+res.mediaUrl}
                              size="contain"
                              preview={false}
                            />
                          }
              >
                  <div className="program-title">
                      <span>{res.title}</span>
                  </div>
                  <div className="program-info">
                      <ul className="program-info-title">
                          <li>수업일</li>
                          <li>교육시간</li>
                          <li>강사명</li>
                          <li>수강료</li>
                      </ul>
                      <ul className="program-info-text">
                          <li>{res?.info?.day.join(', ')}</li>
                          <li>{res?.info?.startTime}~{res?.info?.endTime}</li>
                          <li>{res?.instructor}</li>
                          <li>{fee}원</li>
                      </ul>
                  </div>
              </Card>
              </Link>
          </Col>
        </div>
    </>
  );
}


  export default CardItem;
