import React, {useState, useEffect} from 'react';
import "../assets/styles/Main.css";
import FooterBottom from "../components/footer/FooterBottomWhite";
import Slides from "../components/main/Slides";
import TabView from "../components/main/TabView";
import PosterSlide from "../components/main/PosterSlide";
import GallerySlide from "../components/main/GallerySlide";
import { Link, useHistory } from "react-router-dom";
import { Layout, Row, Col, Typography, Button, Space, Input, Table, Tag, Divider } from "antd";
import SbcBoard from "../components/main/SbcBoard";
const { Title } = Typography;
const { Content } = Layout;

function Main() {

  return (
    <div className="Main">
      <Layout style={{margin:"0 auto", maxWidth:1600}}>
          {/* 메인 슬라이드 부분 */}
          <Row justify="center">
            <Col span={24} md={24} lg={22}><Slides /></Col>
          </Row>
        <Content>
           {/* 행사,성북학 게시판 */}
           <Row  justify="center" style={{padding:"20px 0 100px"}} className="sbcBoard">
            <Col span={22}>
              <SbcBoard />
              </Col>
          </Row>
          {/* 아이콘 메뉴 리스트 (문화강좌) */}
          <Row className="iconList programMain" justify="center" align="middle">
            <Col span={22} sm={4} md={22} lg={4}>
              <ul className="iconListTitle">
                <li className="listText">분야별</li>
                <li className="textBold" >문화강좌</li>
                <li className="listText">모아보기</li>
                <li className="listMore">
                  <Link to="/program/program-all">더보기+</Link>
                </li>
              </ul>
            </Col>
            <Col span={6} sm={3} md={3} lg={2} className="onlyPc">
            <Link to="/program/program-all">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon00.png" alt="강좌 전체" />
                    </li>
                  <li>전체보기</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={3} md={3} lg={2}>
            <Link to="/program/list01">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon01.png" alt="미술,서예" />
                    </li>
                  <li>미술·서예</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={3} md={3} lg={2}>
            <Link to="/program/list02">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon02.png" alt="악기,음악" />
                    </li>
                  <li>악기·음악</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={3} md={3} lg={2}>
            <Link to="/program/list03">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon03.png" alt="댄스,무용" />
                    </li>
                  <li>댄스·무용</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={3} md={3} lg={2}>
            <Link to="/program/list04">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon04.png" alt="생활교양" />
                    </li>
                  <li>생활교양</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={3} md={3} lg={2}>
            <Link to="/program/list05">
                <ul className="iconUl">
                  <li>
                    <img src="/image/icon05.png" alt="어린이" />
                    </li>
                  <li>어린이</li>
                </ul>
              </Link>
            </Col>
            <Col span={7} sm={2} md={4} lg={2}>
            {/*<Link to="/program/list06">*/}
            {/*    <ul className="iconUl">*/}
            {/*      <li>*/}
            {/*        <img src="/image/icon06.png" alt="야간" />*/}
            {/*        </li>*/}
            {/*      <li>야간</li>*/}
            {/*    </ul>*/}
            {/*  </Link>*/}
            </Col>
          </Row>

          {/* 게시판 & 포스터 슬라이드 */}
          <Row justify="center" className="sm-view-padding">
            <Col span={22} lg={21} className='boardTabMain'>
              <TabView />
            </Col>
            {/* <Col span={24} sm={5} className='programSlide'>
              <PosterSlide />
            </Col> */}
          </Row>
          {/* 갤러리 슬라이드 */}
          <Row justify="center" className="gallerySlide">
            <Col span={22} lg={21}>
              <GallerySlide />
              </Col>
          </Row>
           {/* 배너 */}
           <Row style={{padding:"70px 0"}} justify="center" className="banerMain" span={22}>
            <Col span={22} lg={11}>
              <a href="#" style={{display:"inline-block", overflow:"hidden", width:"100%", textAlign: "right"}} target='_blank' rel="noreferrer noopener"
                 onClick={(e)=>{e.preventDefault();
                     window.open('https://page.stibee.com/subscriptions/31802', '', 'top=50, left=100, width=700, height=850, status=no, menubar=no, toolbar=no, resizable=no'); return false
                 }}>
              <img className="onlyPc" src="/image/banner01.png" alt="뉴스레터 구독신청" style={{ width:"100%", maxWidth:"92%", border:"2px solid #F2F2F2"}} />
              <img className="onlyMobile" src="/image/banner02-m.jpg" alt="뉴스레터 구독신청" style={{ width:"100%", border:"2px solid #F2F2F2"}} />
              </a>
            </Col>
            <Col span={22} lg={11}>
            <Link to="/commu/support" style={{display:"inline-block", overflow:"hidden", width:"100%"}}>
                 <img className="onlyPc" src="/image/banner02.jpg" alt="문화원 후원하기" style={{ width:"100%", maxWidth:"92%", border:"2px solid #F2F2F2"}} target='_blank' rel="noreferrer noopener"/>
                 <img className="onlyMobile" src="/image/banner01-m.jpg" alt="문화원 후원하기" style={{ width:"100%", border:"2px solid #F2F2F2"}} target='_blank' rel="noreferrer noopener"/>
              </Link>
            </Col>

          </Row>
        </Content>
      </Layout>
      <FooterBottom />
    </div>
  );
}

export default Main;
