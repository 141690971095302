import React, { useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';

function Support() {
    return(
        <div>
            
            <div id='Support' className='static container'>
                
                <h2 className='title'><strong>기부/후원</strong></h2>
                <div className='MenuRight'>기부/후원</div>
                <div className='MenuLeft'>
                    <div className='Menu'>
                        <div className='SubMenu'>지정기부금</div>
                        <ul style={{margin:'0 0 5%', textAlign:'justify'}}>
                            <li>사회복지, 문화, 예술, 종교 등 공익성을 감안하여 지정한 단체에 기부한 것으로 지방문화원은 2012년 2월 28일 지정기부금단체로 지정되었으며, 
                              2020년 개정된 법인세법 시행령 제39조 및 동법 시행규칙 제18조의3에 따라 성북문화원은 2021년 지정기부금단체로 재지정받았습니다.<br/><br/></li>

                            <li>성북문화원은 개인 및 법인으로부터 공익 목적사업 등에 사용될 기부금을 받고 있으며, 기부금은 지역 문화행사 개최, 지역문화의 개발·보존·활용
                            등 성북문화원 정관상의 고유목적사업을 위해 사용됩니다.<br/><br/></li>
                            <li> 성북문화원에 기부금 납부 시 법인세법 제24조 및 소득세법 제34조에 따라 세제혜택을 받을 수 있습니다.</li>
                        </ul>
                    </div>
                    <div className='Menu'>
                        <div className='SubMenu'>기부 방법</div>
                        <div style={{textAlign:'center'}}>
                          <Button>정기후원</Button>
                          <Button>수시후원</Button>
                        </div>
                        
                    </div>
                    <div className='Menu'>
                    <div className='SubMenu'>혜택</div>
                        <ul style={{margin:'0 0 5%'}}>
                            <li>일반회원 자격부여</li>
                            <li>정기총회 참석 자격부여</li>
                            <li>문화행사, 문화탐방, 특강 등 문화원 행사 우선 초대</li>
                            <li>역사문화 탐방비 무료</li>
                            <li>문화원 관련 소식 알림 서비스</li>
                        </ul>
                    <p>
                        ※ 기부/후원에 참여해주실 분은 아래의 후원하기 버튼 클릭 후 성함 과 연락처를 남겨주시면 개별적으로 연락드립니다.<br/>
                    <span> (자료실에 cms 후원신청서 양식 공지)</span>
                    </p>
                    </div>
                    
                    <div className='ButtonWrapper'>
                        <PopupModal />
                    </div>
                 
                </div>
                <div className='MenuRight'>사용처</div>
            </div>
        </div>
    )
}
export default Support;


// 후원하기 MODAL
function PopupModal() {
    const [isModalVisible, setIsModalVisible] = useState(false);
  
    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
      Modal.success({
        content: '신청되었습니다',
      });
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    return (
      <div className="static container">
        <Button type="primary" onClick={showModal}  className='btnL'>
          후원하기
        </Button>
        <Modal className="static container" title="후원하기 신청" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText='신청' cancelText='취소'>
        <Form labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        >
            <Form.Item label="성함">
                <Input></Input>
            </Form.Item>
            <Form.Item
            label="연락처"
            rules={[
            {
                required: true,
                message: '제목을 입력하세요',
            }
            ]}
            >
                <Input></Input>
            </Form.Item>
      </Form>
        </Modal>
      </div>
    );
  };

