import React from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";
import TextDetail from "../gallery/TextDetail";
import '../../assets/styles/Layout.css';
import '../../assets/styles/Board.css';

// Gallery Type Board

function Cultural(props){
    const { init, userObj } = props;
    const type = 'gallery';
    const cate = 'cultural';
    const group = 'seongbuk'

    return (
        <>
        <Switch>
            <Route path="/seongbuk/cultural/detail/:bbsId" exact>
                <TextDetail cate={cate} group={group} />
            </Route>
            <Route path="/seongbuk/cultural/" exact>
                <GalleryList type={type} cate={cate} group={group} userObj={userObj} init={init} />
            </Route>
        </Switch>
        </>
        );

};

export default Cultural;
