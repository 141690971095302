import React, { useState, useEffect, useRef } from "react";
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom'
import { Space, Button, Input, Table, Row, Col, Typography, Tag } from 'antd';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import axios from "axios";
import moment from "moment";
import {ArrowLeftOutlined} from "@ant-design/icons";

const columns = [
    {
        title: '답변상태',
        dataIndex:'answerCount',
        width:'15%',
        key:'answerCount',
        align: 'center',
        render: item =>  item >= 1 ? <Tag color="green" className="statusTag" >답변완료</Tag> :  <Tag color="orange" className="statusTag" >미완료</Tag>,
        // sorder:(a, b) => a.answerCount > b.answerCount,
        sortOrder: 'answerCount' === 0 && 'answerCount' === 1 ,
        shouldCellUpdate: (record, prevRecord) => true,
    },
    {
        title: '문의유형',
        dataIndex:'type',
        width:'15%',
        key:'type',
        align: 'center',
        shouldCellUpdate: (record, prevRecord) => true,
    },
    {
        title: '제목',
        dataIndex:'title',
        width:'45%',
        key:'title',
        render: text => <a style={{color: '#000000'}}>{text}</a>,
        shouldCellUpdate: (record, prevRecord) => true,
    },
    {
        title: '작성자',
        dataIndex:'createdBy',
        width:"15%",
        key:'createdBy',
        render: name => (name.userNameReal || name.userName),
        align: 'center',
        shouldCellUpdate: (record, prevRecord) => true,
    },
    {
        title: '작성일시',
        dataIndex:`createdAt_format`,
        width:"12.5%",
        align: 'center',
        key:'createdAt',
        className:"grayTxt",
        render: item => moment(item).format('YYYY/MM/DD'),
        shouldCellUpdate: (record, prevRecord) => true,
    },
];


const { Title, Text } = Typography;

export default function MyInquiry(props) {
    const { userInfo, userObj } = props;
    const [ loadingSpin, setLoadingSpin] = useState(true)
    const history = useHistory();
    const [data, setData] = useState([]);

    const {url, path} = useRouteMatch();
    const { params, params2 } = useParams();

    const onHandleBack = () => {
        history.push(`/mypage`);
    };
    
    const getDataAPI = async (_idToken) => {
        const {
            data: response,
    } = await axios.get(`${defaultApiAddress}/inquiries?user=${userObj?.uid}`, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
    // console.log(response.results);
        setData(response.results);
        setTimeout (() => {
            setLoadingSpin(false);
        }, 300)
    };
   
    useEffect(() => {
        if (userObj != null) {
            refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
        }
    }, [userObj]);

    const onRow = (rowData, rowIndex) => {
        // const currentData = rowData;
          return {
              onClick: (event) => {
                  //  rowData: row의 data
                  //  rowIndex: row의 인덱스
                  //  event: event prototype
                  history.push({
                    pathname: `/mypage/inquiry/${rowData.id}`,
                    state: { rowData }
                });
            }
        }
    }

return(
    <>
        <div className="mycontainer myinfo">
             <Button onClick={onHandleBack} type="text" className="historyBtn" ghost>
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            <div className="List">
                <h2>1:1문의</h2>
                <Row align="middle" justify="center" className="detailFooterWrap">
                    <div className="bottomInquiry">
                        <Title level={5} style={{margin:0, fontSize:16}}>찾으시는 내용이 없다면 1:1 문의를 이용하세요.</Title>
                        <Text type="secondary" style={{fontSize:14}}>1:1 문의는 회원만 가능합니다.</Text>
                        <Link to="/commu/inquiry"><Button type="primary">1:1문의 바로가기</Button></Link>
                    </div>
                </Row>
                <Space align="end" size="small" direction="vertical">
                    <Table
                        tableLayout="fixed"
                        loading={ loadingSpin === true ? true : false }
                        expandRowByClick={true}
                        columns={columns}
                        dataSource={data}
                        index={data.id}
                        onRow={onRow}
                        rowKey={data.id}
                        pagination={{
                            pageSize:12
                        }}
                    />
                </Space>
            </div>
        </div>
    </>

  );
}
