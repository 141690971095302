import React from "react";
import { Route, Switch } from "react-router-dom";
import Inquiry from "../components/community/Inquiry";
import Familysite from "../components/static-page/community/Familysite";
import Notice from "../components/community/Notice";
import News from "../components/community/News";
import Public from "../components/community/Public";
import Reference from "../components/community/Reference";
import Sitemap from '../components/static-page/sbcc/Sitemap';
import Support from '../components/static-page/Support';
import '../assets/styles/Layout.css';

function Community(props){
    const {userObj, init} = props;
    return (
        <>
        <Switch>
            <Route path="/commu/notice/">
                <Notice userObj={userObj} init={init} />
            </Route>
            <Route path="/commu/inquiry">
                <Inquiry userObj={userObj} />
            </Route>
            <Route path="/commu/family-sites">
                <Familysite />
            </Route>
            <Route path="/commu/news/">
                <News userObj={userObj} init={init} />
            </Route>
            <Route path="/commu/reference">
                <Reference userObj={userObj} init={init} />
            </Route>
            <Route path="/commu/public">
                <Public userObj={userObj} init={init} />
            </Route>
            <Route path="/commu/sitemap" component={Sitemap}>
            </Route>
            <Route path="/commu/support" component={Support}>
            </Route>
        </Switch>
        </>
        );
};

export default Community;
