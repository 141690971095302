import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import BasicList from "../boardBasic/BasicList";
import AttachDetail from "../attach/AttachDetail";

function Public(props){
    const { userObj, init } = props;
    const history = useHistory();
    let [data, setData] = useState([]);
    const cate = 'public';
    const type = 'attach';

    return (
        <>
            <Switch>
                <Route path="/commu/public/" exact>
                    <BasicList data={data} history={history} cate={cate} type={type} init={init} userObj={userObj} />
                </Route>
                <Route path="/commu/public/detail/:bbsId">
                    <AttachDetail history={history} cate={cate}/>
                </Route>
            </Switch>
        </>
    );
}

export default Public;
