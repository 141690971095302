import React, {useEffect, useState} from 'react';
import {Alert, Button, Steps, Table, Tag} from 'antd';
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import moment from 'moment';
import {ArrowLeftOutlined} from "@ant-design/icons";

function MyAction(props){
    const { userObj, setUserObjFn } = props;
    const [ data, setData ] = useState(null);
    const [ dataIndex, setDataIndex ] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [messageSucc, setMessageSucc] = useState(null);
    const [showMessageErr, setShowMessageErr] = useState(false);
    const [messageErr, setMessageErr] = useState(null);
    const history = useHistory();

    const onHandleBack = () => {
        history.push(`/mypage`);
    };

    const courseCancle = function(index, courseEntryId, nextStatus, _idToken) {
        const _courseEntryId = courseEntryId;
        const _nextStatus = nextStatus;

        if (!_courseEntryId || !_nextStatus) {
            // message.error('요청이 실패하였습니다.');
            setMessageErr('요청 실패하였습니다!');
            setShowMessageErr(true);
            return false
        }

        axios.put(`${defaultApiAddress}/courseEntry/${_courseEntryId}`, {'status':_nextStatus}, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(async function (response){
                await getDataAPI(_idToken);
                setMessageSucc('요청이 완료되었습니다.');
                setShowMessage(true);
                console.log(response);
            })
            .catch(function (error) {
                setMessageErr('요청이 거부되었습니다. 운영자에게 문의해 주세요.');
                setShowMessageErr(true);
                if(error.response){
                    let err_msg = error.response.data;
                    // setErrorMsg(err_msg.message);
                    // errorResult();
                    setMessageErr(err_msg.message);
                    setShowMessageErr(true);
                }
            })
        }

    const successAlert = ()=>{
        setTimeout(()=>{
            setShowMessage(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="완료"
                description={messageSucc}
                type="success"
                showIcon
                closable={true}
            />
        )
    }
    const errorAlert = ()=>{
        setTimeout(()=>{
            setShowMessageErr(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="오류"
                description={messageErr}
                type="error"
                showIcon
                closable={true}
            />
        )
    }

    const getDataAPI = async (_idToken) => {
        if (userObj != null) {
            await axios.get(`${defaultApiAddress}/users/${userObj.uid}`, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(async response => {
                    await setUserObjFn(() => {
                        let _userObj = {...userObj};
                        _userObj.userNameReal = response.data.userNameReal;
                        _userObj.phoneNumber = response.data.phoneNumber;
                        return _userObj;
                    });
                }).catch(async (error) => {
                    if(error.response){
                        let err_msg = await error.response.data;
                        // setErrorMsg(err_msg.message);
                        // errorResult();
                        await setMessageErr(err_msg.message);
                        await setShowMessageErr(true);
                    }
                });

            await axios.get(`${defaultApiAddress}/courses/${userObj.uid}/entryuser?odby=desc`, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(async res => {
                    // console.log(res)
                    setDataIndex(res.data.results.length);
                    setData(res.data.results);
                    let _entryData = [];

                    await res.data.results.forEach((e)=>{
                        let _extraStatus;

                        let _today = new Date();
                        // console.log(moment(e.courseInfo.startDate) - moment(_today));
                        // console.log(moment(e.courseInfo.endDate) - moment(_today));
                        
                        if (!!e.courseInfo) {       // 강의 정보 없으면 강의가 없다고 판단, 스킵
                            // 현재 날짜와 시간에 따른 분기처리   _extraStatus 값들은 DB엔 없고 연산으로 생긴 값. DB에선 모두 DONE
                            if (!!e.courseInfo.startDate && (e.status === 'DONE')) {     // 만약 시작 날짜가 '없다면' 그냥 DONE=입금완료 로 둠
                                if ((moment(_today) - moment(e.courseInfo.startDate)) < 0) {
                                    // console.log('수강시작전');
                                    // _extraStatus = 'COURSE_NOT_STARTED';
                                }
                                if ((moment(e.courseInfo.endDate) - moment(_today)) > 0 && (moment(_today) - moment(e.courseInfo.startDate)) > 0) {
                                    // console.log('둘다 양수 - 수강기간');
                                    // _extraStatus = 'COURSE_DOING';
                                }
                                if ((moment(e.courseInfo.endDate) - moment(_today)) < 0) {
                                    // console.log('수강끝');
                                    // _extraStatus = 'COURSE_FINISHED';
                                }
                            }

                            const _pushData = {
                                id: e.id,
                                number: res.data.results.length-res.data.results.indexOf(e),
                                period: e.courseInfo.startDate ? moment(e.courseInfo.startDate).format('YYYY/MM/DD') + ' ~ ' + moment(e.courseInfo.endDate).format('YYYY/MM/DD') : '',
                                title: e.courseInfo.title,
                                status: _extraStatus ? _extraStatus : e.status,
                                userNameReal: userObj.userNameReal,
                                phoneNumber: userObj.phoneNumber,
                                actionBtn: res.data.results.indexOf(e)+','+e.id + ',' + (_extraStatus ? _extraStatus : e.status)
                            }
                            _entryData.push(_pushData);
                            // console.log(_pushData);
                            // console.log(_entryData);
                        }

                    });

                    await setData(_entryData);
                })
                .catch(err =>{
                    console.log(err)
                })
        }
    };

    useEffect(() => {
        // if(data === null){
        if (userObj != null) {
            refreshToken().then((_idToken)=>{console.log('getToken',_idToken);getDataAPI(_idToken)});
        }
        // }
        return () => {};
    }, []);

    let { page } = useParams();
    const { Step } = Steps;
    const { Column } = Table;
    return(
        <>
            <div className='mycontainer myaction'>
                <Button onClick={onHandleBack} type="text" className="historyBtn" ghost>
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
                <h2>수강신청</h2>
                <div className='contentsWrapper stepWrapper'>
                    <p>신청 진행사항</p>
                    <Steps current={4}>
                        <Step title="입금대기" description="수강료 납부를 기다리고 있습니다" />
                        <Step title="수강신청" description="납부 완료로 수강신청이 완료되었습니다" />
                        <Step title="수강중" description="강좌 프로그램이 진행중에 있습니다" />
                        <Step title="수강완료" description="강좌를 모두 이수하였습니다" />
                    </Steps>
                </div>
                <div className='contentsWrapper'>
                    <p>수강신청 내역</p>
                    <Table dataSource={data}
                           rowKey={dataIndex}>
                        <Column title="번호 " dataIndex="number" key="number" width='32px'/>
                        <Column title="기간" dataIndex="period" key="period" width="210px"/>
                        <Column title="강좌명" dataIndex="title" key="title" width="250px"/>
                        <Column
                            title="수강상태"
                            dataIndex="status"
                            key="status"
                            width='80px'
                            render={e => {
                                // console.log(e);
                                let html;
                                switch(e) {
                                    // default: html = <><Tag color="gold">입금대기</Tag><Tag color="green">입금완료</Tag><Tag color="red">신청취소</Tag><Tag color="orange">환불신청</Tag><Tag color="red">환불완료</Tag><Tag color="green">신청완료</Tag><Tag color="cyan">수강중</Tag><Tag color="geekblue">수강완료</Tag></>; break;
                                    case 'WAITING': html = <Tag color="gold">입금대기</Tag>; break;
                                    case 'DONE': html = <Tag color="green">입금완료</Tag>; break;
                                    case 'CANCELED': html = <Tag color="red">신청취소</Tag>; break;
                                    case 'REFUNDED': html = <Tag color="red">환불완료</Tag>; break;

                                    // 이 아래 값들은 DB값이 아닌 DONE에서 파생된 수동 분기
                                    // case 'COURSE_NOT_STARTED': html = <Tag color="green">신청완료</Tag>; break;
                                    // case 'COURSE_FINISHED': html = <Tag color="cyan">수강중</Tag>; break;
                                    // case 'COURSE_DOING': html = <Tag color="geekblue">수강완료</Tag>; break;
                                }
                                return html;
                            }
                            }
                        />
                        <Column title="수강생 성명" dataIndex="userNameReal" key="userNameReal" width='90px' />
                        <Column title="수강생 연락처" dataIndex="phoneNumber" key="phoneNumber" width='120px' />
                        <Column title="취소/환불" dataIndex="actionBtn" key="actionBtn" width="110px"
                                render = {e => {
                                    let html;
                                    let nextStatus;
                                    if (!!e) {
                                        // console.log(e);
                                        // console.log(_courseEntryId,_status);
                                        const _index = e.split(',')[0]*1;
                                        const _courseEntryId = e.split(',')[1];
                                        const _status = e.split(',')[2];

                                        switch(_status) {
                                            // default: html = <><Tag color="gold">입금대기</Tag><Tag color="green">입금완료</Tag><Tag color="red">신청취소</Tag><Tag color="orange">환불신청</Tag><Tag color="red">환불완료</Tag><Tag color="green">신청완료</Tag><Tag color="cyan">수강중</Tag><Tag color="geekblue">수강완료</Tag></>; break;
                                            case 'WAITING': nextStatus = 'CANCELED';html = <Button type="primary" danger onClick={() => refreshToken().then((_idToken)=>{courseCancle(_index, _courseEntryId, nextStatus, _idToken)})}>신청취소</Button>;break;
                                            case 'DONE': html = '';break;
                                            case 'CANCELED': html = '';break;
                                            case 'REFUNDED': html = '';break;

                                            // 이 아래 값들은 DB값이 아닌 DONE에서 파생된 수동 분기
                                            // case 'COURSE_NOT_STARTED': html = <Button type="primary" danger onClick={() => {history.push("/commu/inquiry")}}>환불신청문의</Button>;break;
                                            // case 'COURSE_FINISHED': html = '';break;
                                            // case 'COURSE_DOING': html = '';break;
                                        }
                                    }
                                    return html;
                                }}
                        />
                    </Table>
                </div>
            </div>
            {showMessage ? successAlert() : null}
            {showMessageErr ? errorAlert() : null}
        </>
    )
}

export default MyAction;
