import React ,{ useState, useEffect }from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import Eculrture from "../components/event/Eculrture";
import Eacademic from "../components/event/Eacademic";
import Gallery from "../components/event/Gallery";
import { Layout, Row, Col, Input, Button, Space, Select, InputNumber, Card, Tag} from "antd";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';

function Event(props){
    const {userObj, init} = props;
    const type = 'gallery';
    const group = 'event'
return (
    <>
    <Switch>
        <Route path="/event/e-culrture/">
            <Eculrture type={type} group={group} init={init} userObj={userObj} />
        </Route>
        <Route path="/event/e-academic/">
            <Eacademic type={type} group={group} init={init} userObj={userObj} />
        </Route>
        <Route path="/event/gallery/">
            <Gallery type={type} group={group} init={init} userObj={userObj} />
        </Route>
    </Switch>
    </>
    );

};

export default Event;
