import React ,{ useState} from "react";
import axios from "axios";
import { Link, Route, useRouteMatch, useHistory, Switch, useParams, useLocation } from "react-router-dom";
import boardData from "../data/boardData.json";
import CourseGuide from "../components/static-page/program/CourseGuide";
import ProgramList from "../components/program/ProgramList";
import ProgramDetail from "../components/program/ProgramDetail";
import "../assets/styles/Program.css";

function Program(props){
  const { url, path } = useRouteMatch();
  const { value, groupValue, userObj, setUserObjFn, init } = props;
  let location = useLocation();
  const dataList = boardData;
  const [ pdata, setData ] = useState(null);

return (
  <>
    <Switch>
      <Route
        name="course-guide"
        path="/program/course-guide"
        >
        <CourseGuide />
      </Route>
      <Route
          name="detail"
          path={`/program/detail/:detailId`}>
        <ProgramDetail
            pdata={pdata}
            location={location}
            value={value}
            userObj={userObj}
            setUserObjFn={setUserObjFn}
            />
      </Route>
      <Route
        name="list"
        path={path}>
          <ProgramList
              value={value}
              userObj={userObj}
              groupValue={groupValue}
              dataList={dataList}
              pdata={pdata}
            init={init} />
        </Route>
    </Switch>
</>
);

};

export default Program;
