import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import BasicList from "../boardBasic/BasicList";
import AttachDetail from "../attach/AttachDetail";

function Reference(props){
    const { userObj, init } = props;
    const history = useHistory();
    const cate = 'reference';
    const type = 'attach';

    return (
        <>
        <Switch>
            <Route path="/commu/reference/" exact>
                <BasicList history={history} cate={cate} type={type} init={init} userObj={userObj} />
            </Route>
            <Route path="/commu/reference/detail/:bbsId" exact>
                <AttachDetail history={history} cate={cate} userObj={userObj} />
            </Route>
        </Switch>
        </>
        );

};

export default Reference;
