import { Layout, Divider, Row, Col } from "antd";
import '../../assets/styles/Footer.css';

const { Footer } = Layout;

function FooterBottom(){

    const footerStyle = {
        backgroundColor:"#F2F2F2",
        width:"100vw",
        margin:0,
        bottom:0,
        left:0,
        right:0,
        position:"relative",
        paddingBottom:0,
        paddingTop:5,
        overflow:"hidden",
    }
    const listGroup ={
        padding:0,
        margin: "1em 0 30px",
        textAlign:"left"
    }
    const iconlistGroup ={
        position: "absolute",
        bottom: -18,
        right: 0,
        padding:0,
        margin: "10px 0",
        textAlign:"right"
    }
    const iconList = {
        display:"inline-block",
        margin: "0 4px",
        lineHeight:"40px"
    }
    
    const textInfo = {
        // height:18,
        textAlign:"left",
        color:"#666",
        lineHeight: 1.2,
        // textTransform: "capitalize",
        letterSpacing: ".5px",
        padding:"2px"
    }
    const widthBox = {
        maxWidth:'1440px', margin:'0 auto', position:'relative'
    }
    const snstext = {
        display: "block", lineHeight: "1.6", textAlign: "center", color:"#666"
    }
    const snsimg = {
        display: "block", margin: "0 auto"
    }
return(
    <Layout>
        <Footer style={footerStyle}>
            <div style={{position:"relative", margin:'0 auto'}}>
                <ul className='termsUl' style={{padding:0, margin:"0 auto", textAlign:"left", maxWidth:'1440px'}}>
                    <li style={{display:"inline-block", marginRight:10 }}>
                        <a href="/termsofuse" style={{ color:"#333333"}}>이용약관</a>
                    </li>
                    <li style={iconList}>ㅣ</li>
                    <li style={iconList}>
                        <a href="/personalterm" style={{ color:"#333333"}}>개인정보처리방침</a>
                    </li>
                </ul>
                <Divider style={{borderColor:"#BDBDBD", margin:"0px auto 0px -50px", width:"100vw"}}/>
                <div style={widthBox}>
                    <ul className="snsBtn" style={iconlistGroup}>
                        <li style={{display:"inline-block", marginRight:10 }}>
                        <a href="https://bit.ly/34AY9cf" target='_blank' rel="noreferrer noopener"  style={{display:"inline-block", width:"63px"}}>
                            <img src="/image/sns01.png" alt="유튜브" style={snsimg}/>
                            <span style={snstext}>유튜브</span>
                            </a>
                        </li>
                        <li style={iconList}>
                        <a href="https://www.instagram.com/seongbuk_cultural_center" target='_blank' rel="noreferrer noopener" style={{display:"inline-block", width:"63px"}}>
                            <img src="/image/sns02.png" alt="인스타" style={snsimg}/>
                            <span style={snstext}>인스타그램</span>
                            </a>
                        </li> 
                        <li style={iconList}>
                        <a href="https://www.facebook.com/%EC%84%B1%EB%B6%81%EB%AC%B8%ED%99%94%EC%9B%90-1856749827941859/" target='_blank' rel="noreferrer noopener" style={{display:"inline-block", width:"63px"}}>
                            <img src="/image/sns03.png" alt="페이스북" style={snsimg}/>
                            <span style={snstext}>페이스북</span>
                            </a>
                        </li> 
                        <li style={iconList}>
                        <a href="https://blog.naver.com/isbcc1" target='_blank' rel="noreferrer noopener" style={{display:"inline-block", width:"63px"}}>
                            <img src="/image/sns04.png" alt="네이버블로그" style={snsimg}/>
                            <span style={snstext}>블로그</span>
                            </a>
                        </li>
                    </ul>
                    <ul style={listGroup}>
                        <li style={{marginBottom:10}}>
                            <img style={{width:"100%", maxWidth:109}} src="/image/footerlogo.png" alt="성북문화원" />
                        </li>
                        <li style={textInfo}>
                        주소 : &#40;02834&#41; 서울시 성북구 성북로4길 13-8 <br className="onlyMobile"/>
                        <span className="onlyMobile" style={{marginLeft:"38px"}}>(동소문동1가 1번지)</span>
                        <span className="onlyPc">(동소문동1가 1번지)</span>
                        </li>
                        <li style={textInfo}>
                        전화 : 02-765-1611 
                        </li>
                        <li style={textInfo}>
                        팩스 : 02-765-1619 
                        </li>
                        <li style={textInfo}>
                        대표메일 : isbcc1@naver.com
                        </li>
                    </ul>                </div>
                <div className='copyright' style={{textAlign:"right",height:"54px",width:"100vw", marginLeft:"-50px", paddingRight:30, backgroundColor:"#5A5A5A"}}>
                    <div style={widthBox}>
                        <span style={{verticalAling:"middle", color:"#ffffff", lineHeight:"3"}}>ⓒ2021. Seongbuk Cultural Center All Rights Reserved.</span>
                    </div>
                </div>
            </div>
           
        </Footer>
    </Layout>
 )
}

export default FooterBottom;

