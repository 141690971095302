import React, { useState, useEffect } from 'react';
import "../assets/styles/Mypage.css";
import {useHistory, useLocation} from "react-router-dom";
import MyPageList from '../components/mypage/MyPageList';
import MyInquiryDetail from '../components/mypage/MyInquiryDetail';
import {Row, Col, Modal, Result, Button} from "antd";
import MyInfo from "../components/mypage/MyInfo";
import MyAction from "../components/mypage/MyAction";
import MyInquiry from "../components/mypage/MyInquiry";
import {authService} from "../middleware/fb";

const MyPage = (props) => {
    const { userObj, setUserObjFn } = props;
    const history = useHistory();
    const location = useLocation();

    const [ errorModal, setErrorModal] = useState(false);

    function loginChk() {
        if (!authService.currentUser) {
            console.log('not logined');
            setErrorModal(true);
        } else {
            console.log('logined success');
        }
    }

    const handleCancel = () => {
        setErrorModal(false);
        authService.signOut();
        history.push('/signin');
    }

    useEffect(()=>{
        let check = setTimeout(loginChk, 3000);

        return ()=>{
            clearTimeout(check);
        }
    },[userObj]);

    const MatchComponent = () => {
        if (location.pathname === '/mypage' || location.pathname === '/mypage/') {
            if (location.pathname === '/mypage/') {history.push('/mypage')}     //만약 주소/mypage/ 로 들어올 경우 강제로 이동. 중첩 가능성 제거
            return <MyPageList userObj={userObj} setUserObjFn={setUserObjFn} />;
        } else if (location.pathname.includes('info')) {
            return <MyInfo userObj={userObj} setUserObjFn={setUserObjFn} />;
        } else if (location.pathname.includes('course')) {
            return <MyAction userObj={userObj} />
        } else if (location.pathname.includes('inquiry')) {
            console.log('pathname',location.pathname.substr(16));
            if (location.pathname?.substr(16).length < 1) {
                return <MyInquiry userObj={userObj} />;
            } else {
                console.log(location.pathname?.substr(16));
                return <MyInquiryDetail userObj={userObj} inquiryId={location.pathname?.substr(16)} />;
            }
        }
    }

    return (
        <>
            <div>
                <Row className="bw-titleWrap" justify="center" align="middle">
                    <Col span={24}>
                        <span className="bw-title">마이페이지</span>
                    </Col>
                </Row>
            </div>
            <MatchComponent />
            <Modal
                visible={errorModal}
                className="applyModal"
                style={{top:'25vh'}}
                width="100%"
                footer={null}>
                <Result
                    status="error"
                    // status="warning"
                    title="로그인 오류!"
                    subTitle="로그인 후 다시 시도해 주세요."
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={handleCancel}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
        </>
    )
}

export default MyPage;

