import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import BasicList from "../boardBasic/BasicList";
import AttachDetail from "../attach/AttachDetail";

function News(props){
    const { userObj, init } = props;
    const history = useHistory();
    let [data, setData] = useState([]);
    const cate = 'news';
    const type = 'attach';

    return (
        <>
        <Switch>
            <Route path="/commu/news/" exact>
                <BasicList history={history} cate={cate} type={type} init={init} userObj={userObj} />
            </Route>
            <Route path="/commu/news/detail/:bbsId" exact>
                <AttachDetail history={history} cate={cate}/>
            </Route>
        </Switch>
        </>
        );

};

export default News;
