import axios from "axios";
import React, { useState, useEffect } from "react";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import {
    Space,
    Button,
    Typography,
    Row,
    Col,
    Divider,
    List,
    Skeleton,
    Upload,
    Alert,
} from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';

const { Title, Text } = Typography;

export default function WithoutSkeletonA(props) {
    const { userObj, history, detailData, cate } = props;
    const location = useLocation();

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

    const [fileList, setFileList] = useState([]);
    const [localToken, setLocalToken] = useState(null);
    const [showSucMsg, setShowSucMsg] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(false);

    const curpathpoint = location.pathname.indexOf('/detail/');
    const prevPath = location.pathname.slice(0, curpathpoint);
    const curpath = location.pathname.slice(0, curpathpoint + 8);

    const prevFunction = () => {
        if(detailData?.nearRow[0] !== null){
            setprevLink({
                link:curpath + detailData?.nearRow[0].id,
                title:detailData?.nearRow[0].title
            })
        }
        if(detailData?.nearRow[0] === null){
            setprevLink({
                link:null,
                title:'이전 게시물이 존재하지 않습니다.'
            })
        }
    }

    const nextFunction = () => {
        if(detailData?.nearRow[1] !== null){
            setnextLink({
                link:curpath + detailData?.nearRow[1].id,
                title:detailData?.nearRow[1].title
            })
        }else{
            setnextLink({
                link:null,
                title:'다음 게시물이 존재하지 않습니다.'
            })
        }
    }

    useEffect(() => {
            refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
            if (!!detailData?.fileUrl) {
                setFileList(detailData?.fileUrl?.fileList);
            }
            prevFunction()
            nextFunction()
            return () => null;
        },
        [detailData?.nearRow, location.pathname],
    );

    const onHandleBack = () => {
        history.push(prevPath);
    };


    const successAlert = () => {
        setTimeout(()=>{
            setShowSucMsg(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="다운로드"
                description="성공하였습니다."
                type="success"
                showIcon
                closable={true}
            />
        )
    };
    const errorAlert = ()=>{
        setTimeout(()=>{
            setShowErrMsg(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="오류"
                description="로그인 후 이용하실 수 있습니다."
                type="error"
                showIcon
                closable={true}
            />
        )
    }

    // 업로드 모듈용 세팅
    const config = {
        fileList: fileList,
        onPreview: (e)=>{   // 파일을 클릭했을때, 주소를 받아서 api 주소로 재조합->클릭(안하면 호스트 주소대로 다운로드 됨)
            if (cate == 'reference') {      // 일단 api상에선 토큰검증을 풀어두고, 클라이언트에서 막아둠(개발자툴 네트워크탭을 확인하지 않는 한 다이렉트 다운로드 링크가 노출되진 않음) by Sarc 210528
                if (userObj == null || userObj == undefined) {
                    setShowErrMsg(true);
                    return false
                }
            }
            axios({
                url: defaultApiAddress+e.response, //your url
                headers: {
                    "Authorization" : localToken,
                    "Access-Control-Allow-Origin" : "*",
                },
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                let filename = e.name;
                let data = response.data;
                let blob = new Blob([data]);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = filename;
                    document.getElementsByTagName('body')[0].appendChild(elem);
                    elem.click();
                    document.getElementsByTagName('body')[0].appendChild(elem);
                }
                setShowSucMsg(true);
            }).catch((error)=>{
                if (!localToken) {
                    setShowErrMsg(true);
                } else {
                    if (error.response) {
                        console.log(error.response.data);
                    }
                }
            });
        },
        showUploadList : {
            showDownloadIcon: true,
            downloadIcon: '다운로드 ',
            showRemoveIcon: false   // 삭제 버튼 비활성화 !!중요!!
        },
        listType : "text",
    };

    return(
        <>
            <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
                <Row className="detailMainInfoWrap">
                    <Col span={2}>
                        <Button onClick={onHandleBack} type="text">
                            <ArrowLeftOutlined
                                style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                            />
                            <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                        </Button>
                    </Col>
                    <Col span={2} offset={18}>
                    </Col>
                    <Col span={2} offset={0}>
                    </Col>
                    <Col span={24} style={{margin:"50px 0 25px", padding:"0 20px"}}>
                        <Title className="titleText">
                            { detailData?.title }
                        </Title>
                        <Text type="secondary" className="secondaryText">
                            <Space>
                                { detailData?.writer }
                                { moment(detailData?.createdAt).format('YY/MM/DD') }
                            </Space>
                        </Text>
                        <Divider className="titleDivider" />

                    </Col>
                </Row>
                <Row style={{padding:"0 36px", marginTop:'-40px', marginBottom:20}} >
                    <Upload  {...config} />
                </Row>
                <Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >
                    {detailData ? (
                        <>
                            <ReactQuill
                                value={detailData?.description}
                                readOnly={true}
                                theme={"bubble"}
                            />
                        </>
                    ) : (
                        <>
                            <Skeleton active />
                        </>
                    )}
                    {/*아래가 게시글 내용 보여주는 방식*/}
                </Row>
                <Row align="middle" justify="center" className="detailFooterWrap">
                    <Divider className="titleDivider" />
                    <Col span={24} style={{margin:"0 0 25px"}}>
                        <List
                            className="prevnextList"
                            itemLayout="horizontal"
                        >
                            <List.Item
                                actions={[<a key="list-loadmore-edit" href="#" onClick={(e)=>{if (nextLink.link !=null) {e.preventDefault();history.push(nextLink.link)}}}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href="#" onClick={(e)=>{if (nextLink.link !=null) {e.preventDefault();history.push(nextLink.link)}}}>{nextLink.title}</a>]}
                            />
                            <List.Item
                                actions={[<a key="list-loadmore-edit" href="#" onClick={(e)=>{if (prevLink.link !=null) {e.preventDefault();history.push(prevLink.link)}}}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href="#" onClick={(e)=>{if (prevLink.link !=null) {e.preventDefault();history.push(prevLink.link)}}}>{prevLink.title}</a>]}
                            />
                        </List>
                    </Col>
                </Row>
            </Row>
            {showSucMsg ? successAlert() : null}
            {showErrMsg ? errorAlert() : null}
        </>
    );
}
