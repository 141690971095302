// services
import axios from "axios";
import {authService} from "../middleware/fb";

let defaultApiAddress;
let defaultHeaders;
let defaultHeadersImg;
let userApi = [];
let bbsApi = [];
let courseApi = [];
let imageApi = [];
let userToken = '';

const keys = {
    kakaoClientToken: "706ab543d1b32b564c89b43b6dfab6ed",
    naverClientId: "NoQWTweAXsgnPlQ1UN4M",
    naverClientSecret: "9DKfQ8_c1s",
    //페북 키는 파이어베이스에서 직접 등록
}

switch (window.location.hostname) {
    case 'isbcc.or.kr':
        defaultApiAddress = '//api.isbcc.or.kr';
        break;
    case 'www.isbcc.or.kr':
        defaultApiAddress = '//api.isbcc.or.kr';
        break;
    case 'dev.isbcc.or.kr':
        defaultApiAddress = '//dev.api.isbcc.or.kr';
        break;
    case 'isbcc-307201.du.r.appspot.com':
        defaultApiAddress = '//sbcc-api-total-dot-isbcc-307201.du.r.appspot.com';
        break;
    case 'default-dev-dot-isbcc-307201.du.r.appspot.com':
        defaultApiAddress = '//sbcc-api-total-dev-dot-isbcc-307201.du.r.appspot.com';
        break;
    case 'localhost':
        defaultApiAddress = 'https://dev.api.isbcc.or.kr';
        // defaultApiAddress = '//localhost:8081';
        break;
    case '127.0.0.1':
        defaultApiAddress = 'https://sbcc-api-total-dev-dot-isbcc-307201.du.r.appspot.com';
        // defaultApiAddress = '//127.0.0.1:8081';
        break;
    default:
        defaultApiAddress = '//sbcc-api-total-dot-isbcc-307201.du.r.appspot.com';
        break;
}

authService.onAuthStateChanged((user) => {
    if (user) {
        userToken = user._lat;
        // console.log('로그인 됨 - '+userToken);
        defaultHeaders = {
            "Authorization" : userToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        };
        defaultHeadersImg = {
            "Authorization" : userToken,
            "Access-Control-Allow-Origin" : "*",
        };
    } else {
        // console.log('로그인 안됨');
        defaultHeaders = {
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        };
        defaultHeadersImg = {
            "Access-Control-Allow-Origin" : "*",
        };
    }
});

const refreshToken = ()=>{
    return new Promise(async (resolve,reject) => {
        if (!!authService.currentUser) {
            authService.currentUser.getIdToken(true).then(function (idToken) {
                // setToken(idToken);
                // window.localStorage.setItem("accessToken", JSON.stringify(idToken));
                // console.log('localStorage 저장(api helper) - ', window.localStorage.getItem("accessToken"));
                resolve(idToken);
            }).catch(function (error) {
                reject("만료됨");
            });
        }
    });
};

export {defaultApiAddress, defaultHeaders, defaultHeadersImg, userApi, bbsApi, courseApi, keys, refreshToken}
