import React, {useState} from "react";
import NaverLogin from 'react-login-by-naver';
import axios from "axios";
import {defaultApiAddress, keys} from "../../services/api.helpers";
import {authService} from "../../middleware/fb";
import {useHistory, useLocation} from "react-router-dom";
import { createBrowserHistory } from "history";
import {Button, message, Modal, Result} from "antd";

const client_id = keys.naverClientId;
// const client_secret = keys.naverClientSecret;

const NaverSignin = (props) => {
    const { userObj, setUserObjFn, validated, setInfoInputVisibleFn, setModeFn } = props;
    const [ errorModal, setErrorModal] = useState(false);
    const [ successModal, setSuccessModal] = useState(false);
    const [ sucMsg, setsucMsg ] = useState(null);
    const [ errMsg, setErrorMsg ] = useState(null);
    const [ cbUrl, setCbUrl ] = useState('/');
    const location = useLocation();
    // createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
    //     forceRefresh: true
    // });

    const history = useHistory();
    const onLogoutClick = () => {
        authService.signOut();
        // history.push("/");
        // createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
        //     forceRefresh: true
        // });
    };

    const handleSuccess = ()=>{

    }
    const handleFail = (url)=>{
        authService.signOut();
        history.push(url);
        createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
            forceRefresh: true
        });
    }
    let _isLogin;

    async function requestToken (res) {
        _isLogin=(window.location.pathname.includes('signup')) ? 'signup' : 'login';
        console.log('test-',_isLogin);

        const uid = 'naver:' + res.id;
        const displayName = res.name;
        const email = res.email;
        const photoUrl = res.profile_image;
        let idToken;
        console.log(res);

        let value = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅,
        }
        let value2 = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email,
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'naver', //여기선 일단 custom으로 해야 커스텀 토큰 발급됨. 추후 provider는 put에서 세팅,
        }
        setUserObjFn(() => {
            return {...value2};
        });

        let instance = axios.create({
            baseURL: defaultApiAddress,
            headers: {
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });
        const customTokenIssue = instance.post(
            'auth/',
            value
        ).then(async response => {
            let customToken = response.data.idToken;

            authService.signInWithCustomToken(customToken)
                .then(async (res) => {
                    idToken = res.user._lat;        // 하단 catch쪽(회원정보 없음)에서 사용하기 위한 저장
                    instance = axios.create({
                        baseURL: defaultApiAddress,
                        headers: {
                            "Authorization" : res.user._lat,
                            "Content-Type" : "application/json",
                            "Access-Control-Allow-Origin" : "*",
                        },
                        timeout: 5000,
                    });

                    const userExistChk = await instance.get(
                        'users/' + uid
                    ).then(async response => {  // 회원정보 획득 성공시 -> 로그인
                        if (_isLogin === 'signup') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                            // alert('이미 가입된 계정입니다! 로그인해 주세요.');
                            // message.error('이미 가입된 계정입니다! 로그인해 주세요.');

                            // await setCbUrl('/signin');
                            // await setErrorMsg('이미 가입된 계정입니다! 로그인해 주세요.');
                            // await setErrorModal(true);

                            // setModeFn('already-registered');
                            // setUserObjFn(null);
                            // onLogoutClick();
                        } else if (_isLogin === 'login') {
                            setModeFn('naver-done');
                            setUserObjFn(async() => {
                                    let _userObj = await {...userObj};
                                    if (response.data.userNameReal && response.data.phoneNumber) {
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                    }
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    _userObj.idToken = res.user._lat;
                                    return _userObj;
                                }
                            );
                            /*await instance.put(
                                'users/' + res.user.uid,
                                {id: res.user.uid, userName: displayName}
                            ).then(async response => {
                                // setModeFn('done');
/!*                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    await setUserObjFn(async() => {
                                        let _userObj = await {...userObj};
                                        _userObj.userNameReal = response.data.userNameReal;
                                        _userObj.phoneNumber = response.data.phoneNumber;
                                        _userObj.provider = response.data.provider;
                                        _userObj.uid = response.data.id;
                                        _userObj.email = response.data.email;
                                        _userObj.displayName = response.data.userName;
                                        _userObj.photoURL = response.data.photoUrl;
                                        _userObj.idToken = res.user._lat;
                                        return _userObj;
                                    });
                                }
                                await setModeFn('naver-done');*!/
                            }).catch(async (error)=>{
                                if (error.response) {
                                    console.log(error.response.data);
                                    if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                        // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                        // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                        await setCbUrl('/signin');
                                        await setErrorMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                        await setErrorModal(true);
                                    }
                                }
                            });*/
                        }

                        try {
                            console.log('try');
                            console.log('islogin-',_isLogin);
                            if (_isLogin === 'signup') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                                // alert('이미 가입된 계정입니다! 로그인해 주세요.');
                                // message.error('이미 가입된 계정입니다! 로그인해 주세요.');

                                // await setCbUrl('/signin');
                                // await setErrorMsg('이미 가입된 계정입니다! 로그인해 주세요.');
                                // await setErrorModal(true);

                                setModeFn('already-registered');
                                setUserObjFn(null);
                                onLogoutClick();
                            }
                        }
                            catch(e) {
                                console.log('debug-2');
                                if (_isLogin === 'login') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                                    // alert('가입이력이 없습니다.');
                                    // message.error('가입이력이 없습니다.');

                                    // await setCbUrl('/signup');
                                    // await setErrorMsg('가입이력이 없습니다.');
                                    // await setErrorModal(true);

                                    setModeFn('not-registered');
                                    onLogoutClick();
                                    setUserObjFn(null);
                                } else if (_isLogin === 'signup') {
                                    setInfoInputVisibleFn(true);
                                    console.log('put-실행');
                                    await instance.put(
                                        'users/' + res.user.uid,
                                        {id: res.user.uid, userName: displayName, provider:'naver'}
                                    ).then(response => {
                                        setUserObjFn(() => {
                                            let _userObj = {...userObj};
                                            if (response.data.userNameReal && response.data.phoneNumber) {
                                                _userObj.userNameReal = response.data.userNameReal;
                                                _userObj.phoneNumber = response.data.phoneNumber;
                                            }
                                            _userObj.provider = response.data.provider;
                                            _userObj.uid = response.data.id;
                                            _userObj.email = response.data.email;
                                            _userObj.displayName = response.data.userName;
                                            _userObj.photoURL = response.data.photoUrl;
                                            _userObj.idToken = res.user._lat;
                                            return _userObj;
                                        });
                                    }).catch(async (error)=>{
                                        if (error.response) {
                                            console.log(error.response.data);
                                            if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                                // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                                // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                                // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                                await setCbUrl('/signin');
                                                await setErrorMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                                await setErrorModal(true);
                                            }
                                        }
                                    });
                                }
                        }
                    });
                })
                .catch((error) => {
                    console.log('debug-1');
                    if (_isLogin === 'login') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                        // alert('가입이력이 없습니다.');
                        // message.error('가입이력이 없습니다.');

                        // await setCbUrl('/signup');
                        // await setErrorMsg('가입이력이 없습니다.');
                        // await setErrorModal(true);

                        setModeFn('not-registered');
                        onLogoutClick();
                        setUserObjFn(null);
                    } else if (_isLogin === 'signup') {
                        setInfoInputVisibleFn(true);
                        console.log('put-실행');
                        instance.put(
                            'users/' + uid,
                            {id: uid, userName: displayName, provider:'naver'}
                        ).then(response => {
                            setUserObjFn(() => {
                                let _userObj = {...userObj};
                                if (response.data.userNameReal && response.data.phoneNumber) {
                                    _userObj.userNameReal = response.data.userNameReal;
                                    _userObj.phoneNumber = response.data.phoneNumber;
                                }
                                _userObj.provider = response.data.provider;
                                _userObj.uid = response.data.id;
                                _userObj.email = response.data.email;
                                _userObj.displayName = response.data.userName;
                                _userObj.photoURL = response.data.photoUrl;
                                _userObj.idToken = idToken;             // TODO 이쪽도 요주의! 커스텀 토큰 발급과정중 문제 발생시 이쪽 가능성이 있음 by Sarc
                                return _userObj;
                            });
                        }).catch(async (error)=>{
                            if (error.response) {
                                console.log(error.response.data);
                                if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                    // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                    // alert('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                    // message.error('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');

                                    await setCbUrl('/signin');
                                    await setErrorMsg('해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.');
                                    await setErrorModal(true);
                                }
                            }
                        });
                }});
        })
    }

    return(
        <>
            <NaverLogin
                clientId={client_id}
                callbackUrl={window.location.origin + "/signin"}
                onSuccess={requestToken}
                onFailure={(res) => console.error(res)}
                isPopup={false}
                render={({ onClick }) => {
                    return (
                        <button
                            className='before naverBtn'
                            onClick={(e) => {
                                // e.preventDefault();  // 이걸 비활성화 해야 버튼이 submit을 보내고 그게 회원가입약관 체크로 이어진다.
                                if (window.location.pathname.includes('signup') && !validated) {
                                    // alert('회원가입시 이용약관 및 개인정보처리방침 동의가 필요합니다.');
                                    // message.error('회원가입시 이용약관 및 개인정보처리방침 동의가 필요합니다.').then(r => {return false;});
                                    return false;
                                }
                                e.preventDefault();

                                onClick();
                            }}
                        >네이버 간편 {((window.location.pathname.includes('signup') ? 'signup' : 'login') == 'signup') ? '회원가입' : '로그인'}
                        </button>
                    );
                }}
            />
            <Modal
                visible={errorModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="error"
                    title="회원가입에 실패하였습니다."
                    subTitle={errMsg}
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={(e)=>{handleFail(cbUrl)}}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
            <Modal
                visible={successModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="success"
                    title="알림"
                    subTitle={sucMsg}
                    extra={[
                        <Button className="btnApply" type="primary" key="console" onClick={handleSuccess}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
        </>
    );
}

export default NaverSignin
