import React, { useState, useEffect } from "react";
import boardData from "../../data/boardData.json";
import { NavLink, useHistory, Link, Route } from "react-router-dom";
import MultiStepMenu from "./MultiStepMenu";
import MobileNavMenu from "./MobileNav";
import Sitemap from '../static-page/sbcc/Sitemap';
import {Alert} from 'antd';

import "../../assets/styles/HeaderNaviMenu.css";

import { authService, dbService } from "../../middleware/fb";
import {message} from "antd";
import { UserOutlined } from "@ant-design/icons";

const HeaderNaviMenu = ({ isLoggedIn, userObj, setUserObjFn }) => {
    let _isUidHaveNaver = userObj?.uid + '';
    _isUidHaveNaver.includes('naver');
    // console.log('header-',_isUidHaveNaver.includes('naver'));
    const [ showMessage, setShowMessage ] = useState(false);

    const history = useHistory();

    const onLogoutClick = () => {
        setShowMessage(true);
        // message.success('로그아웃 되었습니다.');
        authService.signOut();
        setUserObjFn(null);
        setTimeout(()=>{
            setShowMessage(false);
        },4000);
        if (_isUidHaveNaver.includes('naver')) {        // 네이버로 로그인했을 때'만' 로그아웃시 강제 세로고침 timeout
            setTimeout(()=>{
                // console.log('새로고침!');
                window.location.href='/';
            },1000);
        } else {
            history.push("/");
        }
    };

    const successLogout = () => {
        return (
            <Alert
                className="alert-result-true"
                message="로그아웃"
                description="로그아웃되엇습니다."
                type="success"
                showIcon
                closable={true}
            />
        )
    };

  const [ menuList, setMenuList ] = useState(boardData);
  const [ openList, setOpenList ] = useState({
    display: 'none'
  });

 const openMenu = () => {
           setOpenList({ display: 'block'})
      }
 const closeMenu = () => {
           setOpenList({ display: 'none'});
      }

  return (
          <header className="header-navigation">
              <nav className="gnb" onClick={closeMenu}>
                  <ul className="navUl" onClick={closeMenu}>
                      <li className="logo" onClick={closeMenu}>
                          <NavLink to="/" onClick={closeMenu}>
                              <img src="/image/logo.png" alt="성북문화원"/>
                          </NavLink>
                      </li>
                      <MultiStepMenu menuList={menuList} closeMenu={closeMenu} openMenu={openMenu} openList={openList}/>
                      <li className="navMenu achieve">
                          <a href="https://archive.sb.go.kr/isbcc/home/u/index.do" target='_blank' rel="noreferrer noopener">성북마을<br/>아카이브</a>
                      </li>
                      <ul className="navtextUl">
                            <li className="textNav">
                                <NavLink to="/">
                                    HOME
                                </NavLink>
                            </li>
                            <li className="textNav" style={{borderRight:"1px solid #C7C7C7", paddingRight:12}}>
                                <NavLink to="/sbcc/map">
                                    오시는길
                                </NavLink>
                            </li>
                            {(isLoggedIn || userObj != null) ? (
                                <>
                                    {/*<li className="textNav">*/}
                                    {/*    <Link to="/" onClick={(e) => {e.preventDefault();onLogoutClick();}}><UserOutlined className="onlyMobile"/>로그아웃</Link>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <Link to="/mypage"><UserOutlined className="onlyMobile"/>{userObj && '마이페이지'}</Link>*/}
                                    {/*</li>*/}

                                    <li className="textNav">
                                        <Link to="/" onClick={(e) => {e.preventDefault();onLogoutClick();}}>로그아웃</Link>
                                    </li>
                                    <li>
                                        <Link to="/mypage">{
                                            !!userObj?.photoURL ? <span className="onlyMobile" style={{display:"inline-block",width:"40px",height:"40px",position:"absolute",left:"-40px",top:"0",borderRadius:"30%",overflow:"hidden"}}><img style={{width:"100%",height:"100%"}} src={userObj.photoURL} /></span> : <UserOutlined className="onlyMobile"/>
                                        }{userObj && '마이페이지'}</Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="textNav">
                                        <NavLink to="/signin">
                                        <UserOutlined className="onlyMobile"/> 로그인
                                        </NavLink>
                                    </li>
                                    <li className="textNav">
                                        <NavLink to="/signup">
                                            회원가입
                                        </NavLink>
                                    </li>
                                </>
                            )}
                        </ul>
                      {/* hamburgerMunu - site map link */}
                      <Link to="/commu/sitemap">
                      <div className='hamburgerBox'>
                            <div className="hamburger" id="hamburger">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                            </div>
                      </div>
                      </Link>
                      {/* hamburgerMunu */}
                      <MobileNavMenu className="onlyMobile"/>
                  </ul>
              </nav>
              <div className="dropdownWrap" style={openList} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
                  <div className="dropdown">
                    <ul className="snsUl">
                        <li>
                        <a href="https://bit.ly/34AY9cf" target='_blank' rel="noreferrer noopener">
                                <img src="/image/sns01.png" alt="유튜브" />
                            </a>
                        </li>
                        <li>
                        <a href="https://www.instagram.com/seongbuk_cultural_center" target='_blank' rel="noreferrer noopener">
                                <img src="/image/sns02.png" alt="인스타" />
                            </a>
                        </li>
                        <li>
                        <a href="https://www.facebook.com/%EC%84%B1%EB%B6%81%EB%AC%B8%ED%99%94%EC%9B%90-1856749827941859/" target='_blank' rel="noreferrer noopener">
                                <img src="/image/sns03.png" alt="페이스북" />
                            </a>
                        </li>
                        <li>
                        <a href="https://blog.naver.com/isbcc1" target='_blank' rel="noreferrer noopener">
                                <img src="/image/sns04.png" alt="네이버" />
                            </a>
                        </li>
                    </ul>
                  </div>
              </div>
              {/*{showMessage ? successLogout() : null}*/}
          </header>
  )
}
export default HeaderNaviMenu;
