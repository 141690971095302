import React from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Image, Skeleton  } from 'antd';
import { defaultApiAddress } from '../../services/api.helpers';

const BigList = (props) => {
    const { data, pathName } = props;

const cardItemRender = () => {
  if(Array.isArray(data)){
     return data.map((item, index) => {
        return (
            <Col 
            span={12} xs={24} sm={24} md={12} lg={8} xxl={8}  
            key={index} className="galleryBig">
                <div className='GalleryPost'>
                <Link to ={`${pathName}/detail/${item.id}`}>
                    <div className='boradImgBox'>
                        <Image src={defaultApiAddress+item.mediaUrl}
                            alt={item.title} 
                            // placeholder={<Skeleton.Image className="skeletonImageBig" />}
                            preview={false}
                        />
                    </div>
                    <div className='boardTitleBox'>
                        <div className='GalleryPostTitle'>
                            {item.title}
                        </div>
                        <div className='GalleryPostSubtitle'>
                            {moment(item.createdAt).format('YYYY/MM/DD')}
                        </div>
                        </div>
                    </Link>
                </div>
            </Col>
            )
        });
      }
    }

  return (
    <Row justify="center" align="middle" gutter={[12,0]}>
        { data !== null ? cardItemRender(data) : null }
    </Row>
    );
  }
  
  
  export default BigList;