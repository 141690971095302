import React ,{ useState, useEffect } from "react";
import { Tag, Spin } from "antd";
import CardItem from "./CardItem";

function ProgramCard(props){
  const { value, data, reload } = props;
  const [ cardData, setCardData ] = useState(null);

  // 최근 2주 안에 등록된 프로그램에 붙는 new 태그

  const newTag = () => {
    return (
      <Tag color="volcano" style={{height:12, lineHeight:"10px", marginBottom:10}}>new</Tag>
      )
    };

    // 카테고리 전체 프로그램 카드 map함수
  const totalCardMapFilter = (data) => {
        if(Array.isArray(data)){
          return data.map((res, index) => {
              return <CardItem res={res} value={value} key={index}/>
          })
        }
      }
    
    // 카테고리 별 프로그램 카드 map함수
  const cardMapFilter = (data) => {
        if(Array.isArray(data)){
          return data.map((res, index) => {
            const cateLower = res.category.toLowerCase();
           if( value == cateLower ){
              return <CardItem res={res} value={value} key={index}/>
            }
          })
        }
      }

  const isEmptyData = data => Array.isArray(data) ? !data.length : true;

  return(
    <>
      { isEmptyData(data) ? 
         <div style={{minHeight:"400px"}}>
            <Spin size="large" tip="로딩중..." delay="300" />
         </div> : value === 'program-all' ?
            totalCardMapFilter(data) : cardMapFilter(data) }
    </>
  )
};

export default ProgramCard;
