import React, { useState, useEffect } from 'react';
import { useLocation, useHistory} from "react-router"
import { Layout, Row, Col, Menu, Dropdown } from "antd";
import boardData from "../../data/boardData.json";
import { Link } from "react-router-dom";

const { Sider } = Layout;
const getLocationMenuList = (menulist) => menulist && menulist.sublist;

const BoardSidebar = (props) => {
    const { groupValue } = props;
    // 매우 중요 --> groupValue 별로 필터링 해주는 곳.
    const currentSider = {groupValue};
    const getGroupValue = boardData.filter(item => item.groupValue === currentSider.groupValue);


    const siderLocationMenu = (firstStepMenuList) => {
        if(Array.isArray(firstStepMenuList)){
          return firstStepMenuList.map((firstStepMenu,index)=>{
              let secondStepMenuList = getLocationMenuList(firstStepMenu);
              return (
                  <>
                  <ul className="first-side-menu" key={firstStepMenu.id}>
                      <li
                          className="first-side-title"
                          key={`side-step-first-menu-${index}`} 
                          value={firstStepMenu.value}
                          >
                           <span>{firstStepMenu.title}</span>
                      </li>
                  </ul>
                      { drawSecondStepMenu(secondStepMenuList) }
                      
                    </>
              )
          })
        }
      }
      const drawSecondStepMenu = (secondStepMenuList) => {
        if(Array.isArray(secondStepMenuList)){
            return (
                <ul className="second-side-menu" key="side-step-first-ul-1">
                    {
                        secondStepMenuList.map((secondStepMenu, index) => {
                            let thirdStepMenuList = getLocationMenuList(secondStepMenu);
                            return(
                                <div className="second-wrap-div" key={secondStepMenu.id}>
                                    <li
                                    className="second-side-title"
                                    key={`side-step-second-menu-${index}`} 
                                    sub={secondStepMenu.sub} 
                                    value={secondStepMenu.value}>
                                        <Link to={{
                                            pathname:`/${secondStepMenu.groupValue}/${secondStepMenu.value}`,
                                            state:{ title: `${secondStepMenu.title}`,
                                            value: `${secondStepMenu.value}` }
                                            }}>{secondStepMenu.title}</Link>
                                    </li> 
                                    {drawThirdStepMenu(thirdStepMenuList)}
                                </div> 
                            )
                        })
                    }
                </ul>
            )
        }
      }
      
      const drawThirdStepMenu = (thirdStepMenuList) => {
        if(Array.isArray(thirdStepMenuList)){
            return (
                        thirdStepMenuList.map((thirdStepMenu, index) => {
                            return(
                                <li 
                                className="third-side-title"
                                key={`side-step-third-menu-${index}`} 
                                sub={thirdStepMenu.sub} 
                                value={thirdStepMenu.value}>
                                    <Link to={{
                                        pathname:`/${thirdStepMenu.groupValue}/${thirdStepMenu.value}`,
                                        state:{ title: `${thirdStepMenu.title}`,
                                        value: `${thirdStepMenu.value}` }
                                        }}>{thirdStepMenu.title}</Link>
                                </li>
                            )
                        })
            )
        }
      }
    return(
        <Sider style={{ backgroundcolor:"#ffffff", width:"200px", height:"100%"}}>
                <div className="siderWrap" key="sider-menu">
                { siderLocationMenu(getGroupValue) }
                </div>
        </Sider>
    );
}

export default BoardSidebar;