import React ,{ useState, useEffect, useReducer, useMemo } from "react";
import axios from "axios";
import {Row, Pagination } from "antd";
import CategoryBtn from "./CategoryBtn";
import ProgramCard from "./ProgramCard";
import { defaultApiAddress } from '../../services/api.helpers';

function ProgramList(props){

  const { value, userObj, init } = props;
  const [ page, setPage ] = useState(1);
  const [ totalCnt, setTotalCnt ] = useState(0);
  const [ data, setData ] = useState([]);

  // 최초 렌더링 시 발생할 effect
  useEffect(() => {
    let ignore = false;
    let userChkQuery = (userObj != null) ? (`&userId=${userObj.uid}`) : '';
    async function fetchData() {
        setData();
        if(value === 'program-all'){
          const resultTotal = await axios.get(`${defaultApiAddress}/courses/?rpp=12&page=${page}${userChkQuery}`);
            if(!ignore) {
              setData(resultTotal.data.results);
              setTotalCnt(resultTotal.data.totalCount);
              console.log('1번 effect'); }
        }else if(value !== 'program-all'){
          const result = await axios.get(`${defaultApiAddress}/courses?cate=${value}&rpp=12&page=${page}${userChkQuery}`);
          if(!ignore) {
            setData(result.data.results);
            setTotalCnt(result.data.totalCount);
            console.log(result.data);
            console.log('2번 effect');
            }
      }else return null;
    }
      if (init == 'notLogined' || (!!init && !!userObj)) {
          fetchData();
      }
    return () => { ignore = true; }
  }, [page]);
  
return (
      <>
        <div style={{margin:"0 auto 50px", height:"100%"}}>
            <CategoryBtn />
            <Row justify="center" align="middle" className="program-list" gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <ProgramCard
                  data={data}
                  value={value}
                  page={page} />
            </Row>
            <Pagination
              defaultCurrent={1}
              current={page}
              pageSize={12}
              total={totalCnt}
              onChange={(current) => setPage(current)}
              size='small'
            />
        </div>
      </>
        );
};

export default ProgramList;
